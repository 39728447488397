import React, { useEffect, useRef, useState } from "react";
import {
  ArrowDownTrayIcon,
  EnvelopeIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { CareerTimelineType } from "../../Models";
import TimelineLoader from "./TimelineLoader";
import { GetCandidateCv } from "../../WebCalls";
import useApiToken from "../../hooks/useApiToken";
import TimelineHeaderLoader from "./TimelineHeaderLoader";
import { downloadCv } from "../../Helpers";

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  content: CareerTimelineType | null;
  loading?: boolean;
  timelineError?: boolean;
  timeline: JSX.Element;
};

export default function TimelineModal(props: props) {
  const token = useApiToken();

  const [modalTop, setModalTop] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      const viewportHeight = window.innerHeight;
      const modalBottomPosition =
        modalRef.current.getBoundingClientRect().bottom - 150;
      if (modalBottomPosition > viewportHeight) {
        setModalTop(true);
      } else {
        setModalTop(false);
      }
    }
  }, [props.open]);

  const handleCvDownload = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!token || !props.content) return;
    const name = props.content.firstName + "_" + props.content.lastName;
    downloadCv(token, props.content.candidateId, name);
  };

  let recentCandidatePosition = {
    title: "",
    expectedGrad: "",
    noticePeriod: "",
    totalMonths: "",
  };

  let candidateLastPosition: any =
    props.content?.timeline[props.content?.timeline?.length - 1];
  if (candidateLastPosition?.overlappingExperience) {
    const currentPosition = candidateLastPosition?.overlappingExperience.reduce(
      (oe: any, current: any) => {
        const currentEndDate = current.endDate
          ? new Date(current.endDate)
          : new Date();
        const maxEndDate = oe.endDate ? new Date(oe.endDate) : new Date(0);
        return currentEndDate > maxEndDate ? current : oe;
      },
      { endDate: new Date(0) }
    );
    if (
      new Date(currentPosition.endDate) >
      new Date(candidateLastPosition.endDate)
    )
      candidateLastPosition = currentPosition;
  }

  if (candidateLastPosition?.company) {
    recentCandidatePosition.title = candidateLastPosition?.company?.name;
    recentCandidatePosition.noticePeriod =
      props.content?.noticePeriod?.toLocaleString() ?? "";
    recentCandidatePosition.totalMonths = candidateLastPosition?.totalMonths;
  } else {
    recentCandidatePosition.title = candidateLastPosition?.university?.name;
    recentCandidatePosition.totalMonths = candidateLastPosition?.totalMonths;
    if (props.content?.expectedGraduationDate) {
      const date = new Date(props.content.expectedGraduationDate);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
      };
      recentCandidatePosition.expectedGrad = date.toLocaleDateString(
        "en-US",
        options
      );
    } else {
      recentCandidatePosition.expectedGrad = "";
    }
  }

  return (
    <div
      ref={modalRef}
      onMouseEnter={() => props.setOpen(true)}
      onMouseLeave={() => props.setOpen(false)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`absolute ${
        modalTop ? "bottom-4" : "top-4"
      } left-10 rounded-2xl w-fit min-w-[50rem] max-w-[40rem] z-10 border border-gray-200 dark:text-gray-300 dark:border-gray-500`}
    >
      <div className="flex shadow-lg rounded-b-2xl w-full h-screen sm:h-full items-end justify-end sm:p-4 lg:p-0 text-center sm:items-center overflow-hidden">
        <div className="bg-white rounded-2xl h-full w-full dark:bg-darkbglight dark:text-white overflow-y-scroll md:overflow-hidden">
          <div className="dark:border-gray-500 z-10 sticky top-0 rounded-xl border-2 m-1 sm:flex sm:items-start bg-white px-9 py-4 shadow-sm border-b-2 dark:bg-darkbglight dark:shadow-white">
            {props.loading ? (
              <TimelineHeaderLoader />
            ) : (
              <div className="flex items-center justify-between font-medium w-full">
                <div className="flex gap-3 sm:gap-4 items-center">
                  <a
                    href={`mailto:${props.content?.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EnvelopeIcon
                      className="h-6 text-entntblue hover:text-entntorange cursor-pointer"
                      title={props.content?.email}
                    />
                  </a>
                  <ArrowDownTrayIcon
                    className="h-6 text-entntblue hover:text-entntorange cursor-pointer"
                    onClick={(e) => handleCvDownload(e)}
                  />
                </div>
                <h1 className="text-xs text-gray-600 dark:text-gray-200 text-wrap max-w-60">
                  {props.content?.firstName} {props.content?.lastName}
                </h1>
                <h1 className="hidden sm:block max-w-40 text-wrap">{`${
                  recentCandidatePosition.title
                } - ${recentCandidatePosition.totalMonths} ${
                  recentCandidatePosition.expectedGrad
                    ? recentCandidatePosition.expectedGrad &&
                      `(${recentCandidatePosition.expectedGrad})`
                    : recentCandidatePosition.noticePeriod &&
                      `(${recentCandidatePosition.noticePeriod})`
                }`}</h1>
                {/* <div className="flex items-center gap-1">
                      <MapPinIcon className="h-4 w-4" />
                      <span>
                        {props.content?.workLocation1CityName}
                        {props.content?.workLocation2CityName && (
                          <span>, {props.content?.workLocation2CityName}</span>
                        )}
                      </span>
                    </div> */}

                <XCircleIcon
                  className="block lg:hidden w-10 h-10 cursor-pointer text-red-500"
                  onClick={() => props.setOpen(false)}
                />
              </div>
            )}
          </div>
          <div className="h-full px-4 pb-2 flex items-center justify-center">
            {props.loading && !props.timelineError ? (
              <TimelineLoader />
            ) : props.timelineError ? (
              <div className="text-lg font-medium">
                Please try again later as we are currently experiencing
                difficulties loading the Candidate Timeline.
              </div>
            ) : (
              <>{props.timeline}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
