import { Disclosure } from "@headlessui/react";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  ChevronUpDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { City, RoleStatusCandidateCount } from "../../../Models";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  getCompanyRankingCategories,
  getUniversityRankingCategories,
} from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";

export type filterOption = {
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
  extraData?: number;
};
export interface IFilter {
  id: string;
  name: string;
  options: filterOption[];
}
interface IRangeFilter {
  id: string;
  name: string;
  minVal: number;
  maxVal: number;
  currentVal: { min: number; max: number };
}

interface IlocationFilter extends IFilter {
  filteredOptions: filterOption[];
}
const initialFilters: IFilter[] = [
  {
    id: "latestStatusId",
    name: "Status",
    options: [{ value: "", label: "All", checked: true, extraData: 0 }],
  },

  {
    id: "universityRankings",
    name: "University",
    options: [
      { value: 1, label: "1-100", checked: false },
      { value: 2, label: "101-200", checked: false },
      { value: 0, label: "unranked", checked: false },
    ],
  },
  {
    id: "companyRankings",
    name: "Company",
    options: [
      { value: 1, label: "1-100", checked: false },
      { value: 2, label: "101-200", checked: false },
      { value: 0, label: "unranked", checked: false },
    ],
  },
  {
    id: "current",
    name: "Position",
    options: [
      { value: "working", label: "Working", checked: false },
      { value: "studying", label: "Studying", checked: false },
      { value: null, label: "None", checked: false },
    ],
  },
  {
    id: "assignmentSubmission",
    name: "Assignment",
    options: [
      { value: true, label: "Submitted", checked: false },
      { value: null, label: "Not Submitted", checked: false },
    ],
  },
  {
    id: "reApply",
    name: "Application",
    options: [
      { value: null, label: "Single", checked: false },
      { value: true, label: "Multiple", checked: false },
    ],
  },
];

const initialRangeFilters: IRangeFilter[] = [
  {
    id: "yearsOfExperience",
    name: "YOE",
    minVal: 0,
    maxVal: 50,
    currentVal: {
      min: 0,
      max: 50,
    },
  },
  {
    id: "age",
    name: "Age",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
  {
    id: "experienceInMonths",
    name: "Exp",
    minVal: 0,
    maxVal: 60,
    currentVal: {
      min: 0,
      max: 60,
    },
  },
  {
    id: "score",
    name: "Assessment Score",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
];

const initialLocationFilter: IlocationFilter = {
  id: "workLocation1City.Id",
  name: "Location",
  options: [],
  filteredOptions: [],
};

const initialDateFilter: {
  id: string;
  name: string;
  startValue: Date | null;
  endValue: Date | null;
} = {
  id: "latestStatusDate",
  name: "Date",

  startValue: null,
  endValue: null,
};

type props = {
  cities: City[];
  status: RoleStatusCandidateCount;
  rankType: { id: string; name: string };
  handleSearchQuery: (query: string) => void;
  handleFilterQuery: (query: string) => void;
  handleStatusFilterIsAll: (isAll: boolean) => void;
  setSelectedCol: React.Dispatch<React.SetStateAction<string>>;
};
export default function RoleSidebarCandidate({
  cities,
  status,
  rankType,
  handleSearchQuery,
  handleFilterQuery,
  handleStatusFilterIsAll,
}: props) {
  const [rangeFilters, setRangeFilter] = useState(initialRangeFilters);
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [locationFilter, setLocationFilter] = useState(initialLocationFilter);
  const [locationSearch, setLocationSearch] = useState("");
  const [filters, setFilters] = useState(initialFilters);
  const [gradeFilters, setGradeFilters] = useState<IFilter[]>([]);
  const [resetFilter, setResetFilter] = useState(initialFilters);
  const [currentRangeFilters, setCurrentRangeFilter] =
    useState(initialRangeFilters);

  const token = useApiToken();

  useEffect(() => {
    let filteredCity = cities;
    if (locationSearch !== "")
      filteredCity = cities.filter((c) =>
        c.name.toLowerCase().startsWith(locationSearch.toLowerCase())
      );
    const filteredCities = locationFilter.options.filter((lf) => {
      return filteredCity.some((fc) => fc.id === lf.value);
    });

    setLocationFilter((prev) => ({ ...prev, filteredOptions: filteredCities }));
  }, [locationSearch, cities, locationFilter.options]);

  useEffect(() => {
    setFilters((prevFilters) => {
      const filterStatusUpdate = [...prevFilters];
      const updatedOptions: filterOption[] = [
        { value: "", label: "All", checked: true, extraData: 0 },
      ];
      status.roleStatuses.forEach((s) => {
        updatedOptions.push({
          checked: false,
          value: s.id,
          label: s.name,
          extraData: s.candidatesCount as number,
        });
      });
      filterStatusUpdate[0].options = updatedOptions;
      filterStatusUpdate[0].options[0].extraData = status.totalCandidateCount;
      setResetFilter(filterStatusUpdate);
      return filterStatusUpdate;
    });
  }, [status, cities]);

  useEffect(() => {
    if (!token) return;
    const setRanking = async () => {
      const companyRankings = await getCompanyRankingCategories(
        rankType.id,
        token
      );
      const universityRankings = await getUniversityRankingCategories(
        rankType.id,
        token
      );
      setFilters((prevFilters) => {
        const filterStatusUpdate = [...prevFilters];
        const companyRankingOptions: filterOption[] = [];
        const universityRankingOptions: filterOption[] = [];

        companyRankings.forEach((cr) => {
          companyRankingOptions.push({
            checked: false,
            value: cr.rankNumber,
            label: cr.title,
          });
        });

        universityRankings.forEach((ur) => {
          universityRankingOptions.push({
            checked: false,
            value: ur.rankNumber,
            label: ur.title,
          });
        });

        filterStatusUpdate[1].options = universityRankingOptions;
        filterStatusUpdate[2].options = companyRankingOptions;

        setResetFilter(filterStatusUpdate);
        return filterStatusUpdate;
      });
    };

    setRanking();
  }, [token, rankType]);

  useEffect(() => {
    if (!status.roleStatuses) return;
    const filter: IFilter[] = status.roleStatuses.slice(1, -2).map((rs) => ({
      id: rs.id,
      name: rs.name,
      options: [
        { value: 100, label: "Excellent", checked: false },
        { value: 89, label: "Pass", checked: false },
        { value: 79, label: "Fail", checked: false },
      ],
    }));
    setGradeFilters(filter);
  }, [status]);

  useEffect(() => {
    const updatedLocationFilterOption = cities.map((c) => ({
      checked: false,
      value: c.id,
      label: c.name,
    }));

    const locationCityFilter = {
      ...initialLocationFilter,
      options: updatedLocationFilterOption,
    };
    setLocationFilter(locationCityFilter);
  }, [cities]);

  useEffect(() => {
    let query: string[] = [];
    filters.forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.value !== "")
          if (fo.checked) {
            sameFilter.push(
              `${f.id} ${
                f.id === "universityRankings" || f.id === "companyRankings"
                  ? "contains"
                  : fo.value === true
                    ? "ne"
                    : "eq"
              } ${
                typeof fo.value === "string"
                  ? `"${fo.value}"`
                  : fo.value === true
                    ? null
                    : fo.value
              }`
            );
          }
      });
      if (sameFilter.length > 0)
        query.push("(" + sameFilter.join(" or ") + ")");
    });

    gradeFilters.forEach((g) => {
      let sameFilter: string[] = [];
      g.options.forEach((go) => {
        if (go.checked)
          sameFilter.push(
            `candidateStatuses any (roleStatusId,"${g.id}",grade,${go.value})`
          );
      });
      if (sameFilter.length > 0)
        query.push("(" + sameFilter.join(" or ") + ")");
    });

    let lf: string[] = [];
    locationFilter.options.forEach((fo) => {
      if (fo.checked)
        lf.push(
          `workLocation1City.Id eq "${fo.value}" or workLocation2City.Id eq "${fo.value}"`
        );
    });
    if (lf.length > 0) query.push("(" + lf.join(" or ") + ")");

    rangeFilters.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal) {
        query.push(
          `${f.id} gte ${f.currentVal.min} and ${f.id} ste ${f.currentVal.max}`
        );
      }
    });
    if (dateFilter.endValue && dateFilter.startValue)
      query.push(
        `${dateFilter.id} gte "${dateFilter.startValue.toISOString()}" and ${
          dateFilter.id
        } ste "${dateFilter.endValue.toISOString()}"`
      );
    handleFilterQuery(query.join(" and "));
  }, [
    filters,
    rangeFilters,
    dateFilter,
    locationFilter,
    handleFilterQuery,
    gradeFilters,
  ]);

  const handleFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          if (changedOption.value !== "" && option.value === "")
            return { ...option, checked: false };
          if (changedOption.value === "") return { ...option, checked: false };
          else return option;
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });

    let noneStatusSelected = true;
    let allStatusIsSelected = false;
    updatedFilters[0].options.forEach((uf, ind) => {
      if (uf.checked) {
        if (ind === 0) allStatusIsSelected = true;
        noneStatusSelected = false;
        return;
      }
    });
    if (allStatusIsSelected || noneStatusSelected) {
      updatedFilters[0].options[0].checked = true;
      handleStatusFilterIsAll(true);
    } else handleStatusFilterIsAll(false);
    setFilters(updatedFilters);
  };

  const handleGradeFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedFilters = gradeFilters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          } else return option;
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
    setGradeFilters(updatedFilters);
  };

  const handleLocationFilterChange = (changedOption: filterOption) => {
    const updatedOptions = locationFilter.options.map((option) => {
      if (option.value === changedOption.value) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    const updatedFilteredOptions = locationFilter.filteredOptions.map((fo) => {
      if (fo.value === changedOption.value) {
        return { ...fo, checked: !fo.checked };
      }
      return fo;
    });
    setLocationFilter((prev) => ({
      ...prev,
      options: updatedOptions,
      filteredOptions: updatedFilteredOptions,
    }));
  };

  const handleRangeChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = rangeFilters.map((rf) => {
      if (rf.id === section.id) return { ...rf, currentVal: value };
      else return rf;
    });
    if (current) {
      setCurrentRangeFilter(updatedRangeFilter);
    } else {
      setRangeFilter(updatedRangeFilter);
    }
  };

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const delayedSearch = debounce((query: string) => {
    handleSearchQuery(query);
  }, 1000);

  const handleResetFilters = () => {
    setFilters(resetFilter);
    setLocationFilter((prev) => {
      const resetOptions = prev.options.map((o) => ({
        ...o,
        checked: false,
      }));
      return {
        ...prev,
        options: resetOptions,
        filteredOptions: resetOptions,
      };
    });
    setDateFilter(initialDateFilter);
    setRangeFilter(initialRangeFilters);
    setCurrentRangeFilter(initialRangeFilters);
    const filter: IFilter[] = gradeFilters.map((g) => ({
      id: g.id,
      name: g.name,
      options: [
        { value: 100, label: "Excellent", checked: false },
        { value: 89, label: "Pass", checked: false },
        { value: 79, label: "Fail", checked: false },
      ],
    }));
    setGradeFilters(filter);
  };
  return (
    <div className="px-4">
      <div className="relative flex flex-grow focus-within:z-10 mt-2 items-center justify-between">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400 dark:text-gray-300"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block w-10/12 border-0 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-sm bg-transparent dark:text-gray-300 dark:placeholder:text-gray-400 rounded-md"
          placeholder="Quick Search"
          onChange={(e) => {
            delayedSearch(e.target.value);
          }}
        />
        <div
          className="text-gray-400 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-300"
          onClick={handleResetFilters}
        >
          <FunnelIcon width={24} />
          <ArrowPathIcon width={14} className="absolute bottom-0 -right-1" />
        </div>
      </div>
      {filters.map((section, ind) => (
        <Disclosure
          as="div"
          key={section.id}
          className="border-b border-gray-200 py-4 dark:border-gray-500"
        >
          {({ open, close }) => (
            <div className="relative">
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    {section.name}
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>

              <Disclosure.Panel
                className={`${
                  ind === 0
                    ? "pt-4 relative "
                    : "py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500"
                } h-fit`}
              >
                {section.options.length === 0 ? (
                  <div className="text-xs">No filters available</div>
                ) : (
                  <div className={`space-y-2`}>
                    {section.options.map((option, optionIdx) => (
                      <div key={optionIdx} className="flex items-center">
                        <input
                          id={`filter-${section.id}-${optionIdx}`}
                          name={`${section.id}[]`}
                          type={option.value === "" ? "radio" : "checkbox"}
                          onChange={() => {
                            handleFilterChange(section, option);
                          }}
                          checked={option.checked}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />

                        <label
                          htmlFor={`filter-${section.id}-${optionIdx}`}
                          className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                        >
                          <span>{option.label}</span>
                          {option.extraData !== undefined && (
                            <span
                              className={`ml-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block ${
                                option.checked
                                  ? "bg-blue-100 text-entntblue"
                                  : "bg-gray-100 text-gray-900 dark:bg-darkbglight dark:text-gray-400"
                              }`}
                            >
                              {option.extraData}
                            </span>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </Disclosure.Panel>
              {ind !== 0 && open && (
                <div
                  className="fixed inset-0 z-20"
                  onClick={() => {
                    close();
                  }}
                ></div>
              )}
            </div>
          )}
        </Disclosure>
      ))}

      <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 dark:border-gray-500"
      >
        {({ open, close }) => (
          <div className="relative">
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  Grade
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>

            <Disclosure.Panel className="py-2 px-4 pb-3 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-lg h-fit dark:bg-darkbglight dark:border-gray-500">
              {gradeFilters.map((section) => (
                <div key={section.id} className="py-1 divide-y-2">
                  <div className="relative">
                    <h3 className="flow-root">
                      <span className="font-medium text-sm text-gray-600 dark:text-gray-400">
                        {section.name}
                      </span>
                    </h3>

                    <div className={`flex items-center space-x-3`}>
                      {section.options.map((option, optionIdx) => (
                        <div key={optionIdx} className="flex items-center">
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="text-xs text-gray-600 flex justify-between w-full pr-1 dark:text-gray-400"
                          >
                            {option.label}
                          </label>
                          <input
                            id={`gradeFilter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            onChange={() => {
                              handleGradeFilterChange(section, option);
                            }}
                            checked={option.checked}
                            className="h-3 w-3 rounded border-gray-300 text-indigo-600"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </Disclosure.Panel>
            {open && (
              <div
                className="fixed inset-0 z-20"
                onClick={() => {
                  close();
                }}
              ></div>
            )}
          </div>
        )}
      </Disclosure>
      {currentRangeFilters.map((section) => (
        <Disclosure
          as="div"
          key={section.id}
          className="border-b border-gray-200 py-4 dark:border-gray-500"
        >
          {({ open }) => (
            <>
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    {section.name}
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              <Disclosure.Panel className="pt-6 pb-4 px-6">
                <div>
                  <div key={section.id} className="flex items-center">
                    {section.id === "upperage" ? (
                      <Slider
                        range
                        max={section.maxVal}
                        min={0}
                        value={[0, section.currentVal.max]}
                        onChange={(value) => {
                          const [min, max] = value as number[];
                          if (min >= section.minVal && max <= section.maxVal) {
                            handleRangeChange(section, { min, max }, true);
                          }
                        }}
                        onChangeComplete={(value) => {
                          const [min, max] = value as number[];
                          if (min >= section.minVal && max <= section.maxVal) {
                            handleRangeChange(section, { min, max });
                          }
                        }}
                        marks={{
                          [section.minVal]: section.minVal,
                          [section.maxVal]: section.maxVal,
                        }}
                        styles={{
                          track: {
                            background: "#0D42EC",
                          },
                          handle: {
                            borderColor: "#0D42EC",
                          },
                        }}
                      />
                    ) : (
                      <Slider
                        range
                        max={section.maxVal}
                        min={section.minVal}
                        value={[section.currentVal.min, section.currentVal.max]}
                        onChange={(value) => {
                          const [min, max] = value as number[];
                          if (min >= section.minVal && max <= section.maxVal) {
                            handleRangeChange(section, { min, max }, true);
                          }
                        }}
                        onChangeComplete={(value) => {
                          const [min, max] = value as number[];
                          if (min >= section.minVal && max <= section.maxVal) {
                            handleRangeChange(section, { min, max });
                          }
                        }}
                        marks={{
                          [section.minVal]: section.minVal,
                          [section.maxVal]: section.maxVal,
                        }}
                        styles={{
                          track: {
                            background: "#0D42EC",
                          },
                          handle: {
                            borderColor: "#0D42EC",
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}

      <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 dark:border-gray-500"
      >
        {({ open, close }) => (
          <div>
            <div className="relative">
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    {locationFilter.name}
                  </span>
                  <span className="ml-3 flex items-center">
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left ring-1 ring-gray-300 sm:text-sm dark:ring-gray-500">
                      <input
                        type="text"
                        placeholder="Search Location"
                        className="w-full border-none py-1 pl-3 pr-10 text-sm text-gray-900 focus:ring-0 placeholder:text-gray-400 dark:text-gray-300 dark:bg-darkbg"
                        onChange={(event) =>
                          setLocationSearch(event.target.value)
                        }
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 dark:text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </span>
                </Disclosure.Button>
              </h3>

              <Disclosure.Panel
                className={`dark:bg-darkbglight dark:border-gray-500 shadow-sm pl-1 py-1 h-fit absolute bg-white rounded-md z-50 border border-gray-200 bottom-[2.6rem] -right-[0.20rem]`}
              >
                <div className="max-h-[10.5rem] min-w-[11.8rem] pl-2 py-1 overflow-auto thin-scroll bg-transparent z-10">
                  <div className="space-y-2">
                    {locationFilter.filteredOptions.map((option, optionIdx) => (
                      <div key={optionIdx} className="flex items-center">
                        <input
                          id={`locaiton-filter-${optionIdx}`}
                          type={"checkbox"}
                          onChange={() => {
                            handleLocationFilterChange(option);
                          }}
                          checked={option.checked}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />

                        <label
                          id={`locaiton-filter-${optionIdx}`}
                          className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                        >
                          <span>{option.label}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
            {open && (
              <div
                className="fixed inset-0 z-20"
                onClick={() => {
                  close();
                }}
              ></div>
            )}
            <div className="z-10 text-xs flex flex-wrap box-border mt-2">
              {locationFilter.options.map((o, index) =>
                o.checked ? (
                  <span
                    key={o.label + index}
                    className="flex items-center bg-blue-100 text-entntblue py-1 px-2 mr-2 mt-2 rounded-full relative"
                  >
                    {o.label}
                    <XMarkIcon
                      onClick={() => {
                        handleLocationFilterChange(o);
                      }}
                      className="h-4 w-4 cursor-pointer text-gray-500 ml-1 hover:text-gray-600"
                    />
                  </span>
                ) : (
                  <Fragment key={index}></Fragment>
                )
              )}
            </div>
          </div>
        )}
      </Disclosure>

      <div className="border-b border-gray-200 py-4 dark:border-gray-500">
        <h3 className="-my-3 flow-root">
          <div className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-medium text-gray-900 dark:text-gray-300">
              {dateFilter.name}
            </span>
            <span className="flex items-center">
              <div className="flex items-center">
                <ReactDatePicker
                  selected={dateFilter.startValue}
                  onChange={(date) => {
                    const [start, end] = date;
                    end && end.setHours(23, 59, 59, 999);
                    setDateFilter({
                      ...dateFilter,
                      startValue: start,
                      endValue: end,
                    });
                  }}
                  selectsRange
                  isClearable
                  showIcon
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  startDate={dateFilter.startValue}
                  endDate={dateFilter.endValue}
                  icon={<CalendarDaysIcon className="text-gray-400" />}
                  className="placeholder:text-gray-400 text-sm rounded-md border-none ring-1 ring-inset ring-gray-300 block w-[13rem] align-bottom cursor-pointer py-1 dark:bg-darkbg dark:ring-gray-500"
                  placeholderText="  Select period"
                  calendarClassName="datepicker-calendar"
                  wrapperClassName="datepicker-wrapper"
                />
              </div>
            </span>
          </div>
        </h3>
      </div>
    </div>
  );
}
