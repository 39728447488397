export const dateConverter = (date: string, withTime?: boolean): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  if (withTime) {
    options.hour = "numeric";
    options.minute = "numeric";
    options.hour12 = true;
  }
  const convertedDate = new Date(date).toLocaleDateString("en-US", options);
  return convertedDate;
};

export const getCurrentFormattedDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedDate;
};

//Text editor Formats for Quill
export const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "formula",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
];

//Text editor toolbar for Quill
export const toolbar = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
];

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export async function blobToBase64(blob: any) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === "string") {
        const base64String = result.split(",")[1];
        resolve(base64String);
      } else {
        reject(new Error("Error"));
      }
    };
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error"));
    };
    reader.readAsDataURL(blob);
  });
}

export const careerDomain =
  "https://testentntcareers-c8hbbyh2bwcvawev.centralindia-01.azurewebsites.net/role";
