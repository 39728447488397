import { Fragment, useEffect, useRef, useState } from "react";
import LocationGrid from "./Grid/LocationGrid";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import LocationSidebar from "./Sidebar/LocationSidebar";
import { City, NotificationModel } from "../../Models";
import { initialRankType } from "../Roles/Role";
import {
  GetallSubCities,
  GetLocationStatsGeoJson,
  GetMajorCities,
  GetStates,
  MakeMajorCity,
  MakeSubcity,
} from "../../WebCalls";
import MapCombined from "../Map/MapCombined";
import atlas from "azure-maps-control";
import useApiToken from "../../hooks/useApiToken";
import { Spinner } from "../Shared/Spinner";
import LocationLeftSidebar, {
  ITotalCounts,
} from "./Sidebar/LocationLeftSidebar";
import Notification from "../Shared/Notification";
import { Listbox, Transition } from "@headlessui/react";
const tabs = ["SubCity", "City", "State"];
export default function LocationBoard() {
  const [locationFilterQuery, setLocationFilterQuery] = useState("");
  const [candidateFilterQuery, setCandidateFilterQuery] = useState("");
  const [universityFilterQuery, setUniversityFilterQuery] = useState("");
  const [companyFilterQuery, setCompanyFilterQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [isSidebarOpen, setIsSideBarOpen] = useState(true);
  const [isRightSidebarOpen, setIsRightSideBarOpen] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [majorCities, setMajorCities] = useState<City[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("City");
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [selectedCompanyRankType, setSelectedCompanyRankType] = useState<{
    id: string;
    name: string;
  }>(initialRankType);
  const [selectedUniversityRankType, setSelectedUniversityRankType] = useState<{
    id: string;
    name: string;
  }>(initialRankType);
  const [geoJsonData, setGeoJsonData] = useState<atlas.data.FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });
  const [createMapBy, setCreateMapBy] = useState<string>();
  const [loading, setLoading] = useState(false);
  const token = useApiToken();
  const [selectedColumns, setSelectedColumns] = useState<
    { id: string; name: string }[]
  >([]);
  const [majorCityId, setMajorCityId] = useState<string | null>();
  const [majorCityClickPopUp, setMajorCityClickPopUp] = useState(false);
  const [subCityClickPopUp, setSubCityClickPopUp] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState<City[]>([]);
  const [atTheTime, setAtTheTime] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notifcationInfo, setNotifcationInfo] = useState<NotificationModel>({
    title: "",
    description: "",
    type: "success",
  });
  const fetchLocationDataRef = useRef<() => void>(null);
  const [refetchTrigger, setRefetchTrigger] = useState<boolean>(false);
  const mapTypes = ["Candidates", "Universities", "Companies"];
  const [selectedMapType, setSelectedMapType] = useState("");
  const [totalCounts, setTotalCounts] = useState<ITotalCounts>({
    candidates: 0,
    universities: 0,
    companies: 0,
  });
  useEffect(() => {
    if (currentTab === tabs[0]) {
      GetallSubCities().then((c) => {
        setCities(c);
      });
    } else if (currentTab === tabs[1]) {
      setCities(majorCities);
    } else {
      GetStates().then((s) => {
        setCities(s);
      });
    }
  }, [currentTab]);
  useEffect(() => {
    GetMajorCities().then((c) => {
      setCities(c);
      setMajorCities(c);
    });
  }, []);
  // handle Actions
  const handleMakeSubCity = async () => {
    if (!majorCityId || selectedColumns.length === 0) {
      setNotifcationInfo({
        type: "error",
        title: "City Not Updated",
        description: "Something Went Wrong, Try Again",
      });
      setShowNotification(true);
      return;
    }
    handleClosePopup();
    try {
      const cityIds = selectedColumns.map((city) => city.id);
      await MakeSubcity(cityIds, majorCityId, token);
      setNotifcationInfo({
        type: "success",
        title: "City Updated",
        description: " City is set to be a SubCity",
      });
      setShowNotification(true);
      setRefetchTrigger(!refetchTrigger);
    } catch (error) {
      setNotifcationInfo({
        type: "error",
        title: "City Not Updated",
        description: "Something Went Wrong, Try Again",
      });
      setShowNotification(true);
    }
  };

  const handleMakeMajorCity = async () => {
    if (selectedColumns.length === 0) {
      setNotifcationInfo({
        type: "error",
        title: "City Not Updated",
        description: "Something Went Wrong, Try Again",
      });
      setShowNotification(true);
      return;
    }
    handleClosePopup();
    try {
      const cityIds = selectedColumns.map((city) => city.id);
      await MakeMajorCity(cityIds, token);
      setNotifcationInfo({
        type: "success",
        title: "City Updated",
        description: "City is set to a Major City",
      });
      setShowNotification(true);
      if (fetchLocationDataRef.current) {
        fetchLocationDataRef.current();
      }
      setRefetchTrigger(!refetchTrigger);
    } catch (error) {
      setNotifcationInfo({
        type: "error",
        title: "City Not Updated",
        description: "Something Went Wrong, Try Again",
      });
      setShowNotification(true);
    }
  };
  const handleMajorCityClick = () => {
    if (selectedColumns.length > 0) {
      setIsSideBarOpen(false);
      setIsRightSideBarOpen(false);
      setMajorCityClickPopUp(!majorCityClickPopUp);
    } else {
      setNotifcationInfo({
        type: "error",
        title: "No City Selected",
        description: "Please select a city.",
      });
      setShowNotification(true);
    }
  };
  const handleSubCityClick = () => {
    if (selectedColumns.length > 0) {
      setIsSideBarOpen(false);
      setIsRightSideBarOpen(false);
      setSubCityClickPopUp(!subCityClickPopUp);
    } else {
      setNotifcationInfo({
        type: "error",
        title: "No City Selected",
        description: "Please select a city.",
      });
      setShowNotification(true);
    }
  };
  const handleClosePopup = () => {
    setMajorCityClickPopUp(false);
    setSubCityClickPopUp(false);
    setSelectedOption(null);
    setSearchValue("");
    setMajorCityId(null);
    setIsRightSideBarOpen(true);
  };
  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    setSearchValue(option.name);
    setMajorCityId(option.id);
  };
  useEffect(() => {
    if (searchValue === "") {
      setFilteredOptions([]);
      return;
    }
    const results = majorCities.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptions(results);
  }, [searchValue, cities, majorCities]);
  useEffect(() => {
    setFilteredOptions([]);
  }, [selectedOption]);
  //handle Map
  const handleShowMap = (query: string) => {
    setIsSideBarOpen(false);
    setIsRightSideBarOpen(false);
    setCreateMapBy(query);
    setIsMapVisible(false);
    setLoading(true);
  };
  const loadGeoJsonData = async () => {
    try {
      const data = await GetLocationStatsGeoJson(
        locationFilterQuery,
        candidateFilterQuery,
        universityFilterQuery,
        companyFilterQuery,
        "",
        searchQuery,
        1,
        currentTab,
        createMapBy,
        token,
        selectedUniversityRankType.id,
        selectedCompanyRankType.id,
        atTheTime
      );

      setGeoJsonData(data);
      setIsMapVisible(true);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (createMapBy) {
      loadGeoJsonData();
    }
  }, [createMapBy]);
  const getTotalCountForSelectedType = () => {
    switch (createMapBy) {
      case "Candidates":
        return totalCounts.candidates;
      case "Universities":
        return totalCounts.universities;
      case "Companies":
        return totalCounts.companies;
      default:
        return 0;
    }
  };
  // handle Tab Change
  const handleTabChange = (query: string) => {
    setLocationFilterQuery("");
    setCandidateFilterQuery("");
    setUniversityFilterQuery("");
    setCompanyFilterQuery("");
    setSearchQuery("");
    setSelectedCompanyRankType(initialRankType);
    setSelectedUniversityRankType(initialRankType);
    setCreateMapBy("");
    setCurrentTab(query);
    setSelectedColumns([]);
  };
  const handleCloseMap = (flag: boolean) => {
    setIsMapVisible(flag);
    setCreateMapBy("");
  };
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="bg-transparent ">
        <div className="fixed z-40 top-5 left-3/4 transform -translate-x-1/2 flex justify-center">
          {/* Generate Map */}
          <Listbox
            as="div"
            value={selectedMapType}
            onChange={(value) => {
              handleShowMap(value);
            }}
            className="mt-2"
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <div className="inline-flex divide-x divide-entntblue rounded-md shadow-sm">
                    <div className="inline-flex divide-x rounded-md shadow-sm relative after:content-[''] after:absolute  after:left-[-0.5rem] after:bottom-0 after:w-[calc(100%+1rem)] after:h-[2px] after:bg-blue-600">
                      {/* Generate Map Button */}
                      <div className="inline-flex items-center gap-x-1.5 rounded-tl-md min-w-[8rem] bg-blue-600 px-3 py-2 text-white shadow-sm">
                        <p className="text-sm font-semibold">
                          {"Generate Map"}
                        </p>
                      </div>
                      {/* Chevron Button */}
                      <Listbox.Button className="inline-flex items-center rounded-l-none rounded-tr-md bg-blue-600 p-2 hover:bg-entntorange focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-entntblue focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                        <ChevronDownIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </Listbox.Button>
                    </div>
                  </div>
                  {/* Drop Down */}
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute left-0 z-10 -mr-1 mt-2
                         origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md w-32 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0"
                    >
                      {mapTypes.map((type) => (
                        <Listbox.Option
                          key={type}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-entntorange text-white"
                                : "text-gray-900 dark:text-gray-300 dark:bg-darkbglight",
                              "cursor-default select-none p-4 text-sm"
                            )
                          }
                          value={type}
                        >
                          {({ selected, active }) => (
                            <div className="flex flex-col">
                              <div className="flex justify-between">
                                <p className={"font-semibold"}>{type}</p>
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="flex relative">
          <aside
            className={`dark:border-gray-500 fixed z-10 top-[4.1rem] block h-[calc(100vh-4rem)] left-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[18rem] ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10 ${
                isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
              } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsSideBarOpen(!isSidebarOpen);
              }}
            >
              {isSidebarOpen ? (
                <ChevronDoubleLeftIcon width={16} />
              ) : (
                <ChevronDoubleRightIcon width={16} />
              )}
            </div>
            <div
              className={`h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg`}
            >
              <LocationLeftSidebar
                cities={cities}
                currentTab={currentTab}
                handleCurrentTab={(query) => {
                  handleTabChange(query);
                }}
                handleSearchQuery={(query) => setSearchQuery(query)}
                handleLocationFilterQuery={(query) =>
                  setLocationFilterQuery(query)
                }
                handleCandidateFilterQuery={(query) => {
                  setCandidateFilterQuery(query);
                }}
                handleUniversityFilterQuery={(query) => {
                  setUniversityFilterQuery(query);
                }}
                handleCompanyFilterQuery={(query) => {
                  setCompanyFilterQuery(query);
                }}
                selectedUniversityRankType={selectedUniversityRankType}
                setSelectedUniversityRankType={setSelectedUniversityRankType}
                selectedCompanyRankType={selectedCompanyRankType}
                setSelectedCompanyRankType={setSelectedCompanyRankType}
                handleAtTheTimeChange={(value) => setAtTheTime(value)}
                handleTotalCountChange={(value) => setTotalCounts(value)}
              />
            </div>
          </aside>
          <main
            className={`${
              !isSidebarOpen
                ? "w-full"
                : "relative  sm:transform sm:translate-x-[18rem] sm:w-[calc(100%-18rem)]"
            } ${
              !isRightSidebarOpen
                ? "w-full"
                : "relative sm:transform sm:-translate-x-[1rem] sm:w-[calc(100%-14rem)]"
            }
            ${
              isSidebarOpen && isRightSidebarOpen
                ? "sm:w-[calc(100%-38rem)] sm:transform sm:translate-x-[19.1rem]"
                : "w-full"
            }
            ${!isSidebarOpen || !isRightSidebarOpen ? "mx-8" : ""} 
             ${isSidebarOpen && !isRightSidebarOpen ? "mx-0" : ""} 
            transition-all duration-200 
          ease-out`}
          >
            <LocationGrid
              locationFilterQuery={locationFilterQuery}
              candidateFilterQuery={candidateFilterQuery}
              universityFilterQuery={universityFilterQuery}
              companyFilterQuery={companyFilterQuery}
              searchQuery={searchQuery}
              currrentTab={currentTab}
              univRankTypeId={selectedUniversityRankType.id}
              compRankTypeId={selectedCompanyRankType.id}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              atTheTime={atTheTime}
              refetchTrigger={refetchTrigger}
            />
            {!loading && isMapVisible && (
              <MapCombined
                modalVisible={isMapVisible}
                setModalVisible={handleCloseMap}
                geoJsonData={geoJsonData}
                totalCounts={getTotalCountForSelectedType()}
              />
            )}
            {loading && (
              <div className="relative flex flex-col ">
                <div className="fixed inset-0 top-4 flex items-center justify-center backdrop-blur-sm bg-opacity-50 z-30">
                  <Spinner height="h-10" width="w-10" />
                </div>
              </div>
            )}
            {subCityClickPopUp && (
              <div className=" relative flex flex-col  ">
                <div
                  className="fixed inset-0 top-4 flex items-center justify-center backdrop-blur-sm bg-opacity-50 z-30"
                  onClick={handleClosePopup}
                >
                  <div
                    className="  bg-white dark:bg-darkbg dark:text-white p-8 border border-gray-300 rounded-lg shadow-md  min-w-[500px] max-h-[500px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex items-center mb-4">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 mt-1 text-red-600 "
                        aria-hidden="true"
                      />
                      <div className="text-lg ml-2 font-semibold text-center">
                        Are You Sure?
                      </div>
                    </div>
                    <div className="flex items-center justify-evenly">
                      <div className="  font-semibold">Choose Major City :</div>
                      <div className="mt-4">
                        <div className="relative flex items-center mb-4">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            className="block w-full border-0 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-sm bg-transparent dark:text-gray-300 dark:placeholder:text-gray-400 rounded-md"
                            placeholder="Search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />

                          {selectedOption && (
                            <div
                              className="text-gray-400 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-300"
                              onClick={() => {
                                setSelectedOption(null);
                                setSearchValue("");
                                setMajorCityId(null);
                              }}
                            >
                              <XMarkIcon width={24} />
                            </div>
                          )}
                        </div>

                        {filteredOptions.length > 0 && (
                          <div className="absolute z-10 max-w-md overflow-auto thin-scroll mt-2 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 dark:bg-darkbglight">
                            <ul className="max-h-40 overflow-auto thin-scroll">
                              {filteredOptions.map((option, index) => (
                                <li
                                  key={index}
                                  className="cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                                  onClick={() => handleOptionSelect(option)}
                                >
                                  {option.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="mb-2 text-sm text-gray-500">
                      Total Major Cities: {selectedColumns.length}
                    </p>
                    <p className="mb-2  text-gray-500 text-xs">
                      Note: Sub-cities under the listed major cities will now
                      belong to the new Major City.
                    </p>

                    <ul className="list-decimal max-h-60 overflow-y-auto rounded-md pr-4 bg-slate-100 dark:bg-darkbglight dark:text-gray-400">
                      {selectedColumns.map((column, index) => (
                        <li
                          key={column.id}
                          className="pl-4 py-1 font-semibold"
                        >{`${index + 1}. ${column.name}`}</li>
                      ))}
                    </ul>

                    <div className="flex justify-end mt-4">
                      <button
                        className="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded hover:bg-gray-400"
                        onClick={handleClosePopup}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                        onClick={handleMakeSubCity}
                        disabled={selectedOption === null}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Sub City Popup */}
            {majorCityClickPopUp && (
              <div className="relative flex flex-col">
                <div
                  className="fixed inset-0 top-4 flex items-center  justify-center backdrop-blur-sm bg-opacity-50 z-30"
                  onClick={handleClosePopup}
                >
                  <div
                    className="bg-white dark:bg-darkbg  dark:text-white p-8 border border-gray-300 rounded-md shadow-lg min-w-[500px] max-h-[700px] "
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex items-center mb-4">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 mt-1 text-red-600 "
                        aria-hidden="true"
                      />
                      <div className="text-lg ml-2 font-semibold text-center">
                        Are You Sure?
                      </div>
                    </div>

                    <p className="mb-2 text-sm text-gray-500">
                      Total Cities: {selectedColumns.length}
                    </p>

                    <ul className="list-decimal max-h-60 overflow-y-auto pr-4 rounded-md bg-gray-100  dark:bg-darkbglight dark:text-gray-400 ">
                      {selectedColumns.map((column, index) => (
                        <li
                          key={column.id}
                          className="pl-4 py-1 font-semibold"
                        >{`${index + 1}. ${column.name}`}</li>
                      ))}
                    </ul>

                    <div className="flex justify-end mt-4">
                      <button
                        className="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded hover:bg-gray-400"
                        onClick={handleClosePopup}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                        onClick={handleMakeMajorCity}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Notification
              show={showNotification}
              setShow={setShowNotification}
              title={notifcationInfo.title}
              description={notifcationInfo.description}
              type={notifcationInfo.type}
            />
          </main>
          <aside
            className={`dark:border-gray-500 fixed z-10 top-[4.1rem] block h-[calc(100vh-4rem)] right-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[18rem] ${
              isRightSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10 ${
                isRightSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"
              } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsRightSideBarOpen(!isRightSidebarOpen);
              }}
            >
              {isRightSidebarOpen ? (
                <ChevronDoubleRightIcon width={16} />
              ) : (
                <ChevronDoubleLeftIcon width={16} />
              )}
            </div>
            <div
              className={`h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg`}
            >
              <LocationSidebar
                currentTab={currentTab}
                handleCurrentTab={(query) => {
                  handleTabChange(query);
                }}
                handleMajorCityClick={handleMajorCityClick}
                handleSubCityClick={handleSubCityClick}
              />
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}
