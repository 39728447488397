import React, { useEffect, useState } from "react";
import { CategoryFull, City, Role } from "../../../Models";
import {
  ArrowPathIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";

import Slider from "rc-slider";
import useApiToken from "../../../hooks/useApiToken";
import { initialRankType } from "../../Roles/Role";
import {
  getCompanyRankingCategories,
  getRankType,
  GetRoles,
  GetTotalCounts,
  getUniversityRankingCategories,
} from "../../../WebCalls";
const tabs = ["SubCity", "City", "State"];
export type filterOption = {
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
  extraData?: number;
};
export interface IFilter {
  id: string;
  name: string;
  options: filterOption[];
}
export interface IRangeFilter {
  id: string;
  name: string;
  minVal: number;
  maxVal: number;
  currentVal: { min: number; max: number };
}

const initialFilters: IFilter[] = [
  {
    id: "roleId",
    name: "Role",
    options: [],
  },
  {
    id: "universityRankings",
    name: "University",
    options: [
      { value: 1, label: "1-100", checked: false },
      { value: 2, label: "101-200", checked: false },
      { value: 0, label: "unranked", checked: false },
    ],
  },
  {
    id: "companyRankings",
    name: "Company",
    options: [
      { value: 1, label: "1-100", checked: false },
      { value: 2, label: "101-200", checked: false },
      { value: 0, label: "unranked", checked: false },
    ],
  },
  {
    id: "current",
    name: "Position",
    options: [
      { value: "working", label: "Working", checked: false },
      { value: "studying", label: "Studying", checked: false },
      { value: null, label: "None", checked: false },
    ],
  },
];

const initialRangeFilters: IRangeFilter[] = [
  {
    id: "yearsOfExperience",
    name: "YOE",
    minVal: 0,
    maxVal: 50,
    currentVal: {
      min: 0,
      max: 50,
    },
  },

  {
    id: "score",
    name: "Avg. Score",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
  {
    id: "cvGrade",
    name: "Cv Grade",
    minVal: 0,
    maxVal: 200,
    currentVal: {
      min: 0,
      max: 200,
    },
  },
];
const initialUnivRangeFilters: IRangeFilter[] = [
  {
    id: "score",
    name: "Score",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
];
const initialCompRangeFilters: IRangeFilter[] = [
  {
    id: "score",
    name: "Score",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
];

const initialUniversityFilters: IFilter[] = [
  {
    id: "ranking",
    name: "Ranking Category",
    options: [{ value: 0, label: "Unranked", checked: false }],
  },
];
const initialCompanyFilters: IFilter[] = [
  {
    id: "ranking",
    name: "Ranking Category",
    options: [{ value: 0, label: "Unranked", checked: false }],
  },
];
const initialRoleFilter: IFilter = {
  id: "roleId",
  name: "Role",
  options: [{ value: "All", label: "All", checked: true }],
};
const initialAtTheTimeFilter: IFilter = {
  id: "locationType",
  name: "At The Time",
  options: [
    { value: 0, label: "WorkLocation", checked: true },
    { value: 1, label: "University", checked: false },
    { value: 2, label: "1st Job", checked: false },
    { value: 3, label: "2nd Job", checked: false },
    { value: 4, label: "3rd Job", checked: false },
    { value: 5, label: "4th Job", checked: false },
    { value: 6, label: "5th Job", checked: false },
  ],
};

const initialCountPercentageFilters: IRangeFilter[] = [
  {
    id: "candidates",
    name: "Count%",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
  {
    id: "universities",
    name: "Count%",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
  {
    id: "companies",
    name: "Count%",
    minVal: 0,
    maxVal: 100,
    currentVal: {
      min: 0,
      max: 100,
    },
  },
];
export interface ITotalCounts {
  candidates: number;
  universities: number;
  companies: number;
}

type locationLeftSidebarProps = {
  cities: City[];
  currentTab: string;
  handleCurrentTab: (query: string) => void;
  handleSearchQuery: (query: string) => void;
  handleLocationFilterQuery: (query: string) => void;
  handleCandidateFilterQuery: (query: string) => void;
  handleUniversityFilterQuery: (query: string) => void;
  handleCompanyFilterQuery: (query: string) => void;
  selectedUniversityRankType: { id: string; name: string };
  setSelectedUniversityRankType: React.Dispatch<
    React.SetStateAction<{ id: string; name: string }>
  >;
  selectedCompanyRankType: { id: string; name: string };
  setSelectedCompanyRankType: React.Dispatch<
    React.SetStateAction<{ id: string; name: string }>
  >;
  handleAtTheTimeChange: (value: number) => void;
  handleTotalCountChange: (value: ITotalCounts) => void;
};
export default function LocationLeftSidebar({
  cities,
  currentTab,
  handleCurrentTab,
  handleSearchQuery,
  handleLocationFilterQuery,
  handleCandidateFilterQuery,
  handleUniversityFilterQuery,
  handleCompanyFilterQuery,
  selectedUniversityRankType,
  setSelectedUniversityRankType,
  selectedCompanyRankType,
  setSelectedCompanyRankType,
  handleAtTheTimeChange,
  handleTotalCountChange,
}: locationLeftSidebarProps) {
  const [filteredOptions, setFilteredOptions] = useState<City[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [countPercentageFilters, setCountPercentageFilters] = useState(
    initialCountPercentageFilters
  );
  const [currentCountPercentageFilters, setCurrentCountPercentageFilters] =
    useState(initialCountPercentageFilters);
  const [filters, setFilters] = useState(initialFilters);
  const [universityfilters, setUniversityFilters] = useState(
    initialUniversityFilters
  );
  const [companyfilters, setCompanyFilters] = useState(initialCompanyFilters);
  const [rangeFilters, setRangeFilter] = useState(initialRangeFilters);
  const [univRangeFilter, setUnivRangeFilter] = useState(
    initialUnivRangeFilters
  );
  const [compRangeFilter, setCompRangeFilter] = useState(
    initialCompRangeFilters
  );
  const [resetFilter, setResetFilter] = useState(initialFilters);
  const [currentRangeFilters, setCurrentRangeFilter] =
    useState(initialRangeFilters);
  const [currentUnivRangeFilters, setCurrentUnivRangeFilter] = useState(
    initialUnivRangeFilters
  );
  const [currentCompRangeFilters, setCurrentCompRangeFilter] = useState(
    initialCompRangeFilters
  );
  const [compAllRankTypes, setCompAllRankTypes] = useState<
    { id: string; name: string }[]
  >([initialRankType]);
  const [univAllRankTypes, setUnivAllRankTypes] = useState<
    { id: string; name: string }[]
  >([initialRankType]);

  const [allRoles, setAllRoles] = useState<IFilter>(initialRoleFilter);
  const [rolesFilter, setRolesFilter] = useState<IFilter>(initialRoleFilter);
  const [atTheTimeFilter, setAtTheTimeFilter] = useState<IFilter>(
    initialAtTheTimeFilter
  );
  const token = useApiToken();
  const [selcectedAtTheTimeOption, setSelcectedAtTheTimeOption] = useState(0);
  const [totalCounts, setTotalCounts] = useState<ITotalCounts>({
    candidates: 0,
    universities: 0,
    companies: 0,
  });
  const [isSelectedRoleChanged, setIsSelectedRoleChanged] = useState<boolean[]>(
    []
  );
  // Search Use Effects
  useEffect(() => {
    if (searchValue === "") {
      setFilteredOptions([]);
      return;
    }
    const results = cities.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptions(results);
  }, [searchValue, cities]);

  useEffect(() => {
    setFilteredOptions([]);
  }, [selectedOption]);
  // handle Tab Change
  useEffect(() => {
    handleResetFilters();
  }, [currentTab]);
  // Initial data fetching
  useEffect(() => {
    if (!token) return;

    getRankType(token).then((res) => {
      if (res.length > 0) {
        const updatedRankTypes = [...univAllRankTypes, ...res];
        setUnivAllRankTypes(updatedRankTypes);
        setCompAllRankTypes(updatedRankTypes);
      }
    });
    GetRoles().then((res) => {
      if (res.length > 0) {
        const roleOptions = res.map((role: Role) => ({
          value: role.id,
          label: role.title,
          checked: false,
        }));
        setAllRoles({
          ...initialRoleFilter,
          options: [...initialRoleFilter.options, ...roleOptions],
        });
        setFilters((prevFilters) => {
          const filterStatusUpdate = [...prevFilters];
          filterStatusUpdate[0].options = roleOptions;
          setResetFilter(filterStatusUpdate);
          return filterStatusUpdate;
        });
      }
    });
  }, [token]);
  // Candidate Use Effects
  useEffect(() => {
    const roleIds = filters
      .find((filter) => filter.id === "roleId")
      ?.options.filter(
        (fo) => fo.checked && fo.value !== "" && fo.value !== "All"
      )
      .map((fo) => fo.value)
      .filter(
        (value): value is string => value !== null && value !== undefined
      );
    if (token === undefined) {
      return;
    }
    GetTotalCounts(roleIds, token).then((res) => {
      setTotalCounts(res);
      handleTotalCountChange(res);

      setCountPercentageFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === "Candidates"
            ? initialCountPercentageFilters.find((f) => f.id === "Candidates")!
            : filter
        )
      );

      setCurrentCountPercentageFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === "Candidates"
            ? initialCountPercentageFilters.find((f) => f.id === "Candidates")!
            : filter
        )
      );
    });
  }, [token, isSelectedRoleChanged]);

  useEffect(() => {
    setRolesFilter(allRoles);
  }, [allRoles]);

  useEffect(() => {
    let query: string[] = [];
    filters.forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.value !== "")
          if (fo.checked) {
            sameFilter.push(
              `${f.id} ${
                f.id === "universityRankings" ||
                f.id === "companyRankings" ||
                f.id === "roleId"
                  ? "contains"
                  : fo.value === true
                    ? "ne"
                    : "eq"
              } ${
                typeof fo.value === "string"
                  ? `"${fo.value}"`
                  : fo.value === true
                    ? null
                    : fo.value
              }`
            );
          }
      });
      if (sameFilter.length > 0)
        query.push("(" + sameFilter.join(" or ") + ")");
    });

    rangeFilters.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal)
        query.push(
          `${f.id} gte ${f.currentVal.min} and ${f.id} ste ${f.currentVal.max}`
        );
    });

    handleCandidateFilterQuery(query.join(" and "));
  }, [filters, rangeFilters, handleCandidateFilterQuery, rolesFilter]);

  useEffect(() => {
    updateLocationFilterQuery();
  }, [countPercentageFilters, totalCounts]);

  useEffect(() => {
    if (!token) return;
    const setRanking = async () => {
      let companyRankings: CategoryFull[] = [];
      let universityRankings: CategoryFull[] = [];

      try {
        companyRankings = await getCompanyRankingCategories(
          selectedCompanyRankType.id,
          token
        );
      } catch (error) {
        companyRankings = [];
      }

      try {
        universityRankings = await getUniversityRankingCategories(
          selectedUniversityRankType.id,
          token
        );
      } catch (error) {
        universityRankings = [];
      }
      setFilters((prevFilters) => {
        const filterStatusUpdate = [...prevFilters];
        const companyRankingOptions: filterOption[] = [];
        const universityRankingOptions: filterOption[] = [];
        if (companyRankings.length >= 0) {
          companyRankings.forEach((cr) => {
            companyRankingOptions.push({
              checked: false,
              value: cr.rankNumber,
              label: cr.title,
            });
          });
        }
        if (universityRankings.length >= 0) {
          universityRankings.forEach((ur) => {
            universityRankingOptions.push({
              checked: false,
              value: ur.rankNumber,
              label: ur.title,
            });
          });
        }

        filterStatusUpdate[1].options = universityRankingOptions;
        filterStatusUpdate[2].options = companyRankingOptions;

        setResetFilter(filterStatusUpdate);
        return filterStatusUpdate;
      });
      setUniversityFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];

        if (universityRankings.length > 0) {
          const universityRankingOptions: filterOption[] =
            universityRankings.map((ur) => ({
              checked: false,
              value: ur.rankNumber,
              label: ur.title,
            }));
          universityRankingOptions.push({
            value: 0,
            label: "Unranked",
            checked: false,
          });

          if (updatedFilters[0]) {
            updatedFilters[0].options = universityRankingOptions;
          }
        } else {
          updatedFilters[0].options = [];
        }

        return updatedFilters;
      });
      setCompanyFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];

        if (companyRankings.length > 0) {
          const companyRankingOptions: filterOption[] = companyRankings.map(
            (ur) => ({
              checked: false,
              value: ur.rankNumber,
              label: ur.title,
            })
          );
          companyRankingOptions.push({
            value: 0,
            label: "Unranked",
            checked: false,
          });

          if (updatedFilters[0]) {
            updatedFilters[0].options = companyRankingOptions;
          }
        } else {
          updatedFilters[0].options = [];
        }

        return updatedFilters;
      });
    };

    setRanking();
  }, [token, selectedUniversityRankType, selectedCompanyRankType]);
  // University Use Effects
  useEffect(() => {
    let universityQuery: string[] = [];
    universityfilters.forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.value !== "")
          if (fo.checked) {
            sameFilter.push(
              `${f.id} ${
                f.id === "ranking" ? "eq" : fo.value === true ? "ne" : "eq"
              } ${
                typeof fo.value === "string"
                  ? `"${fo.value}"`
                  : fo.value === true
                    ? null
                    : fo.value
              }`
            );
          }
      });
      if (sameFilter.length > 0)
        universityQuery.push("(" + sameFilter.join(" or ") + ")");
    });
    univRangeFilter.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal)
        universityQuery.push(
          `${f.id} gte ${f.currentVal.min} and ${f.id} ste ${f.currentVal.max}`
        );
    });

    handleUniversityFilterQuery(universityQuery.join(" and "));
  }, [universityfilters, univRangeFilter, handleUniversityFilterQuery]);
  //Company Use Effects
  useEffect(() => {
    let query: string[] = [];

    companyfilters.forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.checked) {
          sameFilter.push(
            `${f.id} ${
              f.id === "ranking" ? "eq" : fo.value === true ? "ne" : "eq"
            } ${
              typeof fo.value === "string"
                ? `"${fo.value}"`
                : fo.value === true
                  ? null
                  : fo.value
            }`
          );
        }
      });
      if (sameFilter.length > 0)
        query.push("(" + sameFilter.join(" or ") + ")");
    });
    compRangeFilter.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal)
        query.push(
          `${f.id} gte ${f.currentVal.min} and ${f.id} ste ${f.currentVal.max}`
        );
    });

    handleCompanyFilterQuery(query.join(" and "));
  }, [companyfilters, compRangeFilter, handleCompanyFilterQuery]);
  //Handle Search
  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    setSearchValue(option.name);
    handleQuickSearch(option.name);
  };
  const handleQuickSearch = (query: string) => {
    handleSearchQuery(query);
  };
  // Handle Candidate Filter Change
  const handleFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          if (changedOption.value !== "" && option.value === "")
            return { ...option, checked: false };
          if (changedOption.value === "") return { ...option, checked: false };
          else return option;
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });

    setFilters(updatedFilters);
    const updatedCheckedFlags =
      updatedFilters
        .find((f) => f.id === section.id)
        ?.options.map((o) => o.checked) || [];
    setIsSelectedRoleChanged(updatedCheckedFlags);
  };
  const handleAtTheTimeFilterChange = (value: number) => {
    handleAtTheTimeChange(value);
    setSelcectedAtTheTimeOption(value);
  };
  // Handle Candidate Range Change Filters
  const handleRangeChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = rangeFilters.map((rf) => {
      if (rf.id === section.id) return { ...rf, currentVal: value };
      else return rf;
    });
    if (current) setCurrentRangeFilter(updatedRangeFilter);
    else setRangeFilter(updatedRangeFilter);
  };
  // UniversityFilter
  const handleUniversityRankTypeChange = (rankType: {
    id: string;
    name: string;
  }) => {
    setSelectedUniversityRankType(rankType);
  };

  const handleUniversityFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedUniversityFilters = universityfilters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          if (changedOption.value !== "" && option.value === "")
            return { ...option, checked: false };
          if (changedOption.value === "") return { ...option, checked: false };
          else return option;
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
    setUniversityFilters(updatedUniversityFilters);
  };
  //Handle Univ Range Change Filters
  const handleUnivRangeChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = univRangeFilter.map((rf) => {
      if (rf.id === section.id) return { ...rf, currentVal: value };
      else return rf;
    });
    if (current) setCurrentUnivRangeFilter(updatedRangeFilter);
    else setUnivRangeFilter(updatedRangeFilter);
  };
  // Company Filter
  const handleCompanyRankTypeChange = (rankType: {
    id: string;
    name: string;
  }) => {
    setSelectedCompanyRankType(rankType);
  };

  const handleCompanyFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedCompanyFilters = companyfilters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          if (changedOption.value !== "" && option.value === "")
            return { ...option, checked: false };
          if (changedOption.value === "") return { ...option, checked: false };
          else return option;
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
    setCompanyFilters(updatedCompanyFilters);
  };
  //Handle Comp Range Change Filters
  const handleCompRangeChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = compRangeFilter.map((rf) => {
      if (rf.id === section.id) return { ...rf, currentVal: value };
      else return rf;
    });
    if (current) setCurrentCompRangeFilter(updatedRangeFilter);
    else setCompRangeFilter(updatedRangeFilter);
  };
  const handleCountPercentageChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = countPercentageFilters.map((rf) => {
      if (rf.id === section.id) {
        return { ...rf, currentVal: value };
      }
      return rf;
    });

    if (current) setCurrentCountPercentageFilters(updatedRangeFilter);
    else setCountPercentageFilters(updatedRangeFilter);
  };

  // Handle Location Filter Query
  const updateLocationFilterQuery = () => {
    let filterQuery: string[] = [];

    countPercentageFilters.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal) {
        const key = f.id.toLowerCase() as keyof ITotalCounts;
        const min = Math.floor((f.currentVal.min * totalCounts[key]) / 100);
        const max = Math.ceil((f.currentVal.max * totalCounts[key]) / 100);
        filterQuery.push(`${f.id} gte ${min} and ${f.id} ste ${max}`);
      }
    });

    handleLocationFilterQuery(filterQuery.join(" and "));
  };

  // Reset All Filters
  const handleResetFilters = () => {
    setFilters(resetFilter);
    setRangeFilter(initialRangeFilters);
    setUnivRangeFilter(initialUnivRangeFilters);
    setCompRangeFilter(initialCompRangeFilters);
    setCurrentRangeFilter(initialRangeFilters);
    setCurrentUnivRangeFilter(initialUnivRangeFilters);
    setCurrentCompRangeFilter(initialCompRangeFilters);
    setUniversityFilters(initialUniversityFilters);
    setCompanyFilters(initialCompanyFilters);
    setRolesFilter(allRoles);
    setAtTheTimeFilter(initialAtTheTimeFilter);
    setSelcectedAtTheTimeOption(0);
    setSelectedOption(null);
    setSearchValue("");
    setCurrentCountPercentageFilters(initialCountPercentageFilters);
    setCountPercentageFilters(initialCountPercentageFilters);
  };

  return (
    <div className="px-4 ">
      {/* Tabs */}
      <div className="flex text-sm justify-between items-center py-1 my-1 mt-4 rounded-full bg-white border border-gray-200 relative dark:bg-darkbg dark:border-gray-500">
        <div
          className={`absolute bg-entntblue rounded-full h-[80%] w-[33%] top-[10%] z-0 transform transition-all duration-300 ease-in-out ${
            currentTab === tabs[0]
              ? "translate-x-[0%]"
              : currentTab === tabs[1]
                ? "translate-x-[100%]"
                : "translate-x-[200%]"
          }`}
        ></div>

        <div className="z-10 flex-1 text-center">
          <button
            type="button"
            className={`inline-flex items-center gap-x-1.5 px-3 py-1 text-sm ${
              currentTab === tabs[0]
                ? "text-gray-100"
                : "text-gray-400 font-medium"
            }`}
            onClick={() => handleCurrentTab("SubCity")}
          >
            Sub-City
          </button>
        </div>

        <div className="z-10 flex-1 text-center">
          <button
            type="button"
            className={`inline-flex items-center gap-x-1.5 px-3 py-1 text-sm ${
              currentTab === tabs[1]
                ? "text-gray-100"
                : "text-gray-400 font-medium"
            }`}
            onClick={() => handleCurrentTab("City")}
          >
            City
          </button>
        </div>

        <div className="z-10 flex-1 text-center">
          <button
            type="button"
            className={`inline-flex items-center gap-x-1.5 px-3 py-1 text-sm ${
              currentTab === tabs[2]
                ? "text-gray-100"
                : "text-gray-400 font-medium"
            }`}
            onClick={() => handleCurrentTab("State")}
          >
            State
          </button>
        </div>
      </div>
      {/* Search */}
      <div className="relative flex items-center justify-between mt-4 ">
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-300 dark:text-gray-300"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block w-full border-0 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-sm bg-transparent dark:text-gray-300 dark:placeholder:text-gray-400 rounded-md"
          placeholder="Quick Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {selectedOption && (
          <div
            className="text-gray-400 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-300"
            onClick={() => {
              setSelectedOption(null);
              setSearchValue("");
              handleQuickSearch("");
            }}
          >
            <XMarkIcon width={24} />
          </div>
        )}
        <div
          className="text-gray-300 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-500 ml-4"
          onClick={handleResetFilters}
        >
          <FunnelIcon width={24} />
          <ArrowPathIcon width={14} className="absolute bottom-0 -right-1" />
        </div>
      </div>

      {/* Dropdown for filtered options */}
      {filteredOptions.length > 0 && (
        <div className="absolute z-10  max-w-md overflow-auto thin-scroll mt-2  bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 dark:bg-darkbglight">
          <ul className="max-h-60 overflow-auto thin-scroll">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className="cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                onClick={() => handleOptionSelect(option)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Candidate Filters */}
      <Disclosure
        as="div"
        className="border-b border-gray-300 py-4 dark:border-gray-500 mt-1"
      >
        {({ open }) => (
          <div className="relative">
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  Candidate
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>

            <Disclosure.Panel className="pt-4 relative pl-2">
              {
                <Disclosure
                  as="div"
                  key={currentCountPercentageFilters[0].id}
                  className="border-b border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {countPercentageFilters[0].name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div
                            key={currentCountPercentageFilters[0].id}
                            className="flex items-center"
                          >
                            <Slider
                              range
                              max={currentCountPercentageFilters[0].maxVal}
                              min={currentCountPercentageFilters[0].minVal}
                              value={[
                                currentCountPercentageFilters[0].currentVal.min,
                                currentCountPercentageFilters[0].currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[0].minVal &&
                                  max <= currentCountPercentageFilters[0].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[0],
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[0].minVal &&
                                  max <= currentCountPercentageFilters[0].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[0],
                                    { min, max }
                                  );
                                }
                              }}
                              marks={{
                                [currentCountPercentageFilters[0].minVal]:
                                  currentCountPercentageFilters[0].minVal,
                                [currentCountPercentageFilters[0].maxVal]:
                                  currentCountPercentageFilters[0].maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              }
              {filters.map((section, ind) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-b border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open, close }) => (
                    <div className="relative">
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>

                      <Disclosure.Panel
                        className={
                          "py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500 h-fit"
                        }
                      >
                        {section.options.length === 0 ? (
                          <div className="text-xs">No filters available</div>
                        ) : (
                          <div className="space-y-2">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={optionIdx}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  type={
                                    option.value === "" ? "radio" : "checkbox"
                                  }
                                  onChange={() => {
                                    handleFilterChange(section, option);
                                  }}
                                  checked={option.checked}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                                >
                                  <span>{option.label}</span>
                                  {option.extraData !== undefined && (
                                    <span
                                      className={`ml-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block ${
                                        option.checked
                                          ? "bg-blue-100 text-entntblue"
                                          : "bg-gray-100 text-gray-900 dark:bg-darkbglight dark:text-gray-400"
                                      }`}
                                    >
                                      {option.extraData}
                                    </span>
                                  )}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </Disclosure.Panel>
                      {open && (
                        <div
                          className="fixed inset-0 z-20"
                          onClick={() => {
                            close();
                          }}
                        ></div>
                      )}
                    </div>
                  )}
                </Disclosure>
              ))}
              {currentRangeFilters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-b border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div key={section.id} className="flex items-center">
                            <Slider
                              range
                              max={section.maxVal}
                              min={section.minVal}
                              value={[
                                section.currentVal.min,
                                section.currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleRangeChange(
                                    section,
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleRangeChange(section, { min, max });
                                }
                              }}
                              marks={{
                                [section.minVal]: section.minVal,
                                [section.maxVal]: section.maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
              {
                <Disclosure
                  as="div"
                  className="border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <div className="relative">
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            At The Time
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>

                      <Disclosure.Panel
                        className={
                          "py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500 h-fit"
                        }
                      >
                        {atTheTimeFilter.options.length === 0 ? (
                          <div className="text-xs">No roles available</div>
                        ) : (
                          <div className="space-y-2">
                            {atTheTimeFilter.options.map(
                              (option, optionIdx) => (
                                <div
                                  key={optionIdx}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`role-${optionIdx}`}
                                    name={`role-${optionIdx}`}
                                    type="radio"
                                    onChange={() =>
                                      handleAtTheTimeFilterChange(
                                        typeof option.value === "number"
                                          ? option.value
                                          : 0
                                      )
                                    }
                                    checked={
                                      selcectedAtTheTimeOption === option.value
                                    }
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`role-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                                  >
                                    <span>{option.label}</span>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              }
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      {/* University Filters */}
      <Disclosure
        as="div"
        className="border-b border-gray-300 py-4 dark:border-gray-500"
      >
        {({ open }) => (
          <div className="relative">
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  University
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>

            <Disclosure.Panel className="pt-4 relative pl-2">
              {
                <Disclosure
                  as="div"
                  key={currentCountPercentageFilters[1].id}
                  className="border-b border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {currentCountPercentageFilters[1].name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div
                            key={currentCountPercentageFilters[1].id}
                            className="flex items-center"
                          >
                            <Slider
                              range
                              max={currentCountPercentageFilters[1].maxVal}
                              min={currentCountPercentageFilters[1].minVal}
                              value={[
                                currentCountPercentageFilters[1].currentVal.min,
                                currentCountPercentageFilters[1].currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[1].minVal &&
                                  max <= currentCountPercentageFilters[1].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[1],
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[1].minVal &&
                                  max <= currentCountPercentageFilters[1].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[1],
                                    { min, max }
                                  );
                                }
                              }}
                              marks={{
                                [currentCountPercentageFilters[1].minVal]:
                                  currentCountPercentageFilters[1].minVal,
                                [currentCountPercentageFilters[1].maxVal]:
                                  currentCountPercentageFilters[1].maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              }
              {/* Rank Type Selection */}
              <Disclosure
                as="div"
                className="border-b border-gray-200 py-4 dark:border-gray-500"
              >
                {({ open, close }) => (
                  <div className="relative">
                    <h3 className="-my-4 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                        <span className="font-medium text-gray-600 dark:text-gray-300">
                          Rank Type
                        </span>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>

                    <Disclosure.Panel
                      className="py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500
                          h-fit"
                    >
                      {univAllRankTypes.length === 0 ? (
                        <div className="text-xs">No rank types available</div>
                      ) : (
                        <div className="space-y-2">
                          {univAllRankTypes.map((option, optionIdx) => (
                            <div key={optionIdx} className="flex items-center">
                              <input
                                id={`univranktype-${optionIdx}`}
                                name="univrankType"
                                type="radio"
                                onChange={() =>
                                  handleUniversityRankTypeChange(option)
                                }
                                checked={
                                  selectedUniversityRankType.id === option.id
                                }
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`univranktype-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                              >
                                <span>{option.name}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </Disclosure.Panel>
                    {open && (
                      <div
                        className="fixed inset-0 z-20"
                        onClick={() => {
                          close();
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </Disclosure>

              {/* Rank Category Selection */}
              {selectedUniversityRankType.id !== "Overall" &&
                universityfilters.map((section, ind) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-b border-gray-200 py-4 dark:border-gray-500"
                  >
                    {({ open, close }) => (
                      <div className="relative">
                        <h3 className="-my-4 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                            <span className="font-medium text-gray-600 dark:text-gray-300">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel
                          className="py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500
                          h-fit"
                        >
                          {section.options.length === 0 ? (
                            <div className="text-xs">No filters available</div>
                          ) : (
                            <div className="space-y-2">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={optionIdx}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    type={
                                      option.value === "" ? "radio" : "checkbox"
                                    }
                                    onChange={() => {
                                      handleUniversityFilterChange(
                                        section,
                                        option
                                      );
                                    }}
                                    checked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                                  >
                                    <span>{option.label}</span>
                                    {option.extraData !== undefined && (
                                      <span
                                        className={`ml-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block ${
                                          option.checked
                                            ? "bg-blue-100 text-entntblue"
                                            : "bg-gray-100 text-gray-900 dark:bg-darkbglight dark:text-gray-400"
                                        }`}
                                      >
                                        {option.extraData}
                                      </span>
                                    )}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </Disclosure.Panel>
                        {open && (
                          <div
                            className="fixed inset-0 z-20"
                            onClick={() => {
                              close();
                            }}
                          ></div>
                        )}
                      </div>
                    )}
                  </Disclosure>
                ))}
              {currentUnivRangeFilters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div key={section.id} className="flex items-center">
                            <Slider
                              range
                              max={section.maxVal}
                              min={section.minVal}
                              value={[
                                section.currentVal.min,
                                section.currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleUnivRangeChange(
                                    section,
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleUnivRangeChange(section, { min, max });
                                }
                              }}
                              marks={{
                                [section.minVal]: section.minVal,
                                [section.maxVal]: section.maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      {/* Company Filters */}
      <Disclosure
        as="div"
        className="border-b border-gray-300 py-4 dark:border-gray-500"
      >
        {({ open }) => (
          <div className="relative">
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  Company
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>

            <Disclosure.Panel className="pt-4 relative pl-2">
              {
                <Disclosure
                  as="div"
                  key={currentCountPercentageFilters[0].id}
                  className="border-b border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {currentCountPercentageFilters[2].name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div
                            key={currentCountPercentageFilters[2].id}
                            className="flex items-center"
                          >
                            <Slider
                              range
                              max={currentCountPercentageFilters[2].maxVal}
                              min={currentCountPercentageFilters[2].minVal}
                              value={[
                                currentCountPercentageFilters[2].currentVal.min,
                                currentCountPercentageFilters[2].currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[2].minVal &&
                                  max <= currentCountPercentageFilters[2].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[2],
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >=
                                    currentCountPercentageFilters[2].minVal &&
                                  max <= currentCountPercentageFilters[2].maxVal
                                ) {
                                  handleCountPercentageChange(
                                    currentCountPercentageFilters[2],
                                    { min, max }
                                  );
                                }
                              }}
                              marks={{
                                [currentCountPercentageFilters[2].minVal]:
                                  currentCountPercentageFilters[2].minVal,
                                [currentCountPercentageFilters[2].maxVal]:
                                  currentCountPercentageFilters[2].maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              }
              {/* Rank Type Selection */}
              <Disclosure
                as="div"
                className="border-b border-gray-200 py-4 dark:border-gray-500"
              >
                {({ open }) => (
                  <div className="relative">
                    <h3 className="-my-4 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                        <span className="font-medium text-gray-600 dark:text-gray-300">
                          Rank Type
                        </span>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>

                    <Disclosure.Panel className="py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500 h-fit">
                      {compAllRankTypes.length === 0 ? (
                        <div className="text-xs">No rank types available</div>
                      ) : (
                        <div className="space-y-2">
                          {compAllRankTypes.map((option, optionIdx) => (
                            <div key={optionIdx} className="flex items-center">
                              <input
                                id={`compranktype-${optionIdx}`}
                                name="comprankType"
                                type="radio"
                                onChange={() =>
                                  handleCompanyRankTypeChange(option)
                                }
                                checked={
                                  selectedCompanyRankType.id === option.id
                                }
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`compranktype-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                              >
                                <span>{option.name}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>

              {/* Rank Category Selection */}
              {selectedCompanyRankType.id !== "Overall" &&
                companyfilters.map((section, ind) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-b border-gray-200 py-4 dark:border-gray-500"
                  >
                    {({ open, close }) => (
                      <div className="relative">
                        <h3 className="-my-4 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                            <span className="font-medium text-gray-600 dark:text-gray-300">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel className="py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500 h-fit">
                          {section.options.length === 0 ? (
                            <div className="text-xs">No filters available</div>
                          ) : (
                            <div className="space-y-2">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={optionIdx}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    type={
                                      option.value === "" ? "radio" : "checkbox"
                                    }
                                    onChange={() => {
                                      handleCompanyFilterChange(
                                        section,
                                        option
                                      );
                                    }}
                                    checked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                                  >
                                    <span>{option.label}</span>
                                    {option.extraData !== undefined && (
                                      <span
                                        className={`ml-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block ${
                                          option.checked
                                            ? "bg-blue-100 text-entntblue"
                                            : "bg-gray-100 text-gray-900 dark:bg-darkbglight dark:text-gray-400"
                                        }`}
                                      >
                                        {option.extraData}
                                      </span>
                                    )}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </Disclosure.Panel>
                        {open && (
                          <div
                            className="fixed inset-0 z-20"
                            onClick={() => {
                              close();
                            }}
                          ></div>
                        )}
                      </div>
                    )}
                  </Disclosure>
                ))}
              {currentCompRangeFilters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-gray-200 py-4 dark:border-gray-500"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div key={section.id} className="flex items-center">
                            <Slider
                              range
                              max={section.maxVal}
                              min={section.minVal}
                              value={[
                                section.currentVal.min,
                                section.currentVal.max,
                              ]}
                              onChange={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleCompRangeChange(
                                    section,
                                    { min, max },
                                    true
                                  );
                                }
                              }}
                              onChangeComplete={(value) => {
                                const [min, max] = value as number[];
                                if (
                                  min >= section.minVal &&
                                  max <= section.maxVal
                                ) {
                                  handleCompRangeChange(section, { min, max });
                                }
                              }}
                              marks={{
                                [section.minVal]: section.minVal,
                                [section.maxVal]: section.maxVal,
                              }}
                              styles={{
                                track: {
                                  background: "#0D42EC",
                                },
                                handle: {
                                  borderColor: "#0D42EC",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}
