import { useEffect, useRef, useState } from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
type Headers = {
  name: string;
  asc: boolean;
  fieldName: string;
  sort: boolean;
  isActive: boolean;
};
const initialHeading: Headers[] = [
  {
    name: "Location",
    fieldName: "locationName",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "Nearest City",
    fieldName: "nearestCity",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "State",
    fieldName: "locationState",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "Candidates",
    fieldName: "candidates",
    asc: false,
    sort: true,
    isActive: false,
  },
  {
    name: "Universities",
    fieldName: "universities",
    asc: false,
    sort: true,
    isActive: false,
  },
  {
    name: "Companies",
    fieldName: "companies",
    asc: false,
    sort: true,
    isActive: false,
  },
  {
    name: "Avg. Score",
    fieldName: "averageScore",
    asc: false,
    sort: true,
    isActive: false,
  },
  {
    name: "Avg. CvGrade",
    fieldName: "cvGrade",
    asc: false,
    sort: true,
    isActive: false,
  },
];

type props = {
  currentTab: string;
  handleSort: (query: string) => void;
  handleSelectedAll: (flag: boolean) => void;
};
export default function LocationGridHeader({
  handleSort,
  currentTab,
  handleSelectedAll,
}: props) {
  const [sort, setSort] = useState<Headers[]>([]);
  const [sortOptions, setSortOptions] = useState(initialHeading);
  const updatedSortOptions = initialHeading.filter(
    (option) => option.name !== "Nearest City"
  );
  const updatedStateSortOptions = initialHeading.filter(
    (option) => option.name !== "State" && option.name !== "Nearest City"
  );
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState<boolean>(false);
  useEffect(() => {
    handleSort("");
    setSort([]);
    const handleHeaderChange = () => {
      if (currentTab === "City") {
        setSortOptions(updatedSortOptions);
      } else if (currentTab === "State") {
        setSortOptions(updatedStateSortOptions);
      } else {
        setSortOptions(initialHeading);
      }
    };
    handleHeaderChange();
    setChecked(false);
  }, [currentTab]);
  useEffect(() => {
    let sortQueryList: string[] = [];
    sort.forEach((s) => {
      if (s.fieldName === "name") {
        sortQueryList.push(`firstName${s.asc ? "" : " desc"}`);
        sortQueryList.push(`lastName${s.asc ? "" : " desc"}`);
      } else sortQueryList.push(`${s.fieldName}${s.asc ? "" : " desc"}`);
    });
    handleSort(sortQueryList.join(","));
  }, [sort, handleSort]);

  const handleSortChange = (sortOption: Headers) => {
    let updatedSort = [...sort];
    const updatedSortedoption = sortOptions.map((so) => {
      if (so.isActive && so.name === sortOption.name) {
        if (so.asc) {
          let changedSort = updatedSort.find((us) => us.name === so.name);
          if (changedSort) changedSort.asc = false;
          return { ...so, asc: false };
        } else {
          const ind = updatedSort.findIndex((us) => us.name === so.name);
          if (ind !== -1) {
            updatedSort.splice(ind, 1);
          }
          return { ...so, asc: true, isActive: false };
        }
      } else if (!so.isActive && so.name === sortOption.name) {
        updatedSort = [...updatedSort, { ...so, isActive: true }];
        return { ...so, isActive: true };
      } else {
        return so;
      }
    });

    setSortOptions(updatedSortedoption);
    setSort(updatedSort);
  };
  const handleToggleAll = () => {
    handleSelectedAll(!checked);
    setChecked(!checked);
  };

  return (
    <thead className="sticky top-16 bg-white dark:bg-darkbglight z-20 before:border-b before:border-gray-300 before:absolute before:content-[''] before:w-full before:h-full before:-z-10 dark:before:border-gray-500 dark:before:border-t">
      <tr className="">
        {currentTab !== "State" && (
          <th scope="col" className="relative px-0 sm:w-12 sm:px-0">
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
              ref={checkbox}
              checked={checked}
              onChange={handleToggleAll}
            />
          </th>
        )}

        {sortOptions.map((s, i) => (
          <th
            key={s.name}
            scope="col"
            className={`py-3 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 w-fit min-w-14`}
          >
            <div className="flex items-center">
              <span>{s.name}</span>
              {s.sort && (
                <span
                  className={`cursor-pointer hover:bg-gray-200 rounded-full dark:hover:bg-entntorange py-0.5 px-1.5 flex items-center ${
                    s.isActive && "bg-blue-100 dark:bg-entntblue"
                  }`}
                  onClick={() => {
                    handleSortChange(s);
                  }}
                >
                  <span
                    className={
                      s.isActive ? "text-entntblue dark:text-gray-300" : ""
                    }
                  >
                    {s.asc ? (
                      <ArrowUpIcon height={15} />
                    ) : (
                      <ArrowDownIcon height={15} />
                    )}
                  </span>
                  <span
                    className={`${
                      s.isActive && "text-entntblue dark:text-gray-300"
                    } text-xs font-medium`}
                  >
                    {s.isActive &&
                      sort.findIndex((st) => st.name === s.name) + 1}
                  </span>
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}
