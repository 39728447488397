import React, { useEffect, useState } from "react";
import Map from "./Map";
import MapSettings from "./MapSettings";
import atlas from "azure-maps-control";

import {
  AdjustmentsHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

type props = {
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  geoJsonData: atlas.data.FeatureCollection;
  totalCounts: number;
};
const MapCombined = ({
  modalVisible,
  setModalVisible,
  geoJsonData,
  totalCounts,
}: props) => {
  const [maxWeight, setMaxWeight] = useState(
    Math.floor((20 * totalCounts) / 100)
  );
  const [layerType, setLayerType] = useState<"heatmap" | "cluster">("cluster");
  const [intensity, setIntensity] = useState(0.6);
  const [radius, setRadius] = useState(30);
  const [popupProperties, setPopupProperties] = useState<string[]>([
    "name",
    "count",
  ]);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const calculateActualWeight = (percentage: number) => {
    const actualWeight = Math.floor((percentage / 100) * totalCounts);
    setMaxWeight(actualWeight);
  };

  return (
    <div className="relative flex flex-col h-screen ">
      {modalVisible && (
        <div
          className="fixed inset-0 top-4 flex items-center justify-center backdrop-blur-sm bg-opacity-50 z-30"
          onClick={() => setModalVisible(false)}
        >
          <div
            className="bg-white rounded-lg shadow-lg w-11/12 h-[85%] top-2 relative"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close Button */}
            <button
              onClick={() => setModalVisible(false)}
              className="absolute top-0 -right-10 bg-red-600 text-white p-2 transition duration-200 ease-in-out transform hover:scale-105 rounded-full shadow-md z-40"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {/* Map Component with Settings Button */}
            <div className="relative w-full h-full">
              <div className="absolute top-2 left-2 z-40">
                <button
                  onClick={() => setSettingsVisible(!settingsVisible)}
                  className="bg-entntblue text-white p-2 rounded shadow-md z-40 transition duration-200 ease-in-out transform hover:scale-105"
                >
                  <AdjustmentsHorizontalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="w-full h-full">
                <Map
                  geoJsonData={geoJsonData}
                  maxWeight={maxWeight}
                  layerType={layerType}
                  intensity={intensity}
                  radius={radius}
                  popupProperties={popupProperties}
                />
              </div>
            </div>

            {/* MapSettings Component */}
            {settingsVisible && (
              <div className="absolute top-16 left-4 w-1/4 backdrop-blur-sm p-4 rounded shadow-md z-50">
                <MapSettings
                  maxWeight={maxWeight}
                  setWeightFromPercentage={calculateActualWeight}
                  layerType={layerType}
                  setLayerType={setLayerType}
                  intensity={intensity}
                  setIntensity={setIntensity}
                  radius={radius}
                  setRadius={setRadius}
                  totalCounts={totalCounts}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapCombined;
