import React, { useEffect, useState } from "react";
import { LocationStats } from "../../../Models";
import LocationSubCityPopUp from "./LocationSubCityPopUp";
import { Spinner } from "../../Shared/Spinner";

interface LocationGridItemListProps {
  stateColumn: boolean;
  popupVisible: boolean;
  popupData: LocationStats[];
  isPopupLoading: boolean;
  nearestCityColumn: boolean;
  locations: LocationStats[];
  selectedColumn: { id: string; name: string }[];
  handleSelectColumn: (id: string, name: string) => void;
  handlePopUpVisible: (flag: boolean) => void;
  handleCityClicked: (id: string) => void;
}

export default function LocationGridItemList({
  stateColumn,
  popupVisible,
  popupData,
  isPopupLoading,
  nearestCityColumn,
  locations,
  selectedColumn,
  handleSelectColumn,
  handlePopUpVisible,
  handleCityClicked,
}: LocationGridItemListProps) {
  const [clicked, setCliked] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const handleCityClick = (locationId: string) => {
    setCliked(locationId);
    handleCityClicked(locationId);
  };
  const containsSelectedLocation = (id: string): boolean => {
    return selectedColumn.some((city) => city.id === id);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (popupVisible) {
        handlePopUpVisible(false);
      }
    };

    if (popupVisible) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [popupVisible, handlePopUpVisible]);
  return (
    <>
      <tbody className="divide-y divide-gray-200 bg-white  dark:bg-darkbg dark:divide-gray-600">
        {locations.map((location) => (
          <tr
            key={location.locationId}
            className="cursor-default hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200"
          >
            {stateColumn && (
              <td className="relative p-0 max-w-1  cursor-default">
                <input
                  type="checkbox"
                  className={`z-[11] bg-transparent absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 `}
                  checked={containsSelectedLocation(location.locationId)}
                  onChange={() =>
                    handleSelectColumn(
                      location.locationId,
                      location.locationName
                    )
                  }
                />
              </td>
            )}
            <td className=" px-4 py-1 sm:w-12 sm:px-3 ">
              <div className="relative ">
                <div
                  className={` text-gray-900 font-medium dark:text-gray-200 ${
                    stateColumn && !nearestCityColumn
                      ? "cursor-pointer transition duration-200 ease-in-out transform hover:scale-105 hover:text-entntblue dark:hover:text-entntblue"
                      : "cursor-default"
                  }`}
                  onClick={() =>
                    stateColumn &&
                    !nearestCityColumn &&
                    handleCityClick(location.locationId)
                  }
                >
                  {location.locationName}
                </div>

                {!isPopupLoading &&
                clicked === location.locationId &&
                popupVisible ? (
                  <LocationSubCityPopUp
                    locations={popupData}
                    handlePopVisible={() => handlePopUpVisible(false)}
                  />
                ) : (
                  isPopupLoading &&
                  clicked === location.locationId && (
                    <div className="absolute top-0 left-28">
                      <Spinner />
                    </div>
                  )
                )}
              </div>
            </td>
            {nearestCityColumn && (
              <td className="relative px-3 py-1 00 sm:w-12 text-gray-900 sm:px-3 cursor-default dark:text-gray-200">
                {location.nearestCity}
              </td>
            )}
            {stateColumn && (
              <td className="relative px-3 py-1 00 sm:w-12 text-gray-900 sm:px-3 cursor-default dark:text-gray-200">
                {location.locationState}
              </td>
            )}
            <td className="relative px-3 py-1 sm:w-12 text-gray-900  sm:px-3 cursor-default dark:text-gray-200">
              {location.candidates}
            </td>
            <td className="relative px-3  py-1 sm:w-12 text-gray-900  sm:px-3 cursor-default dark:text-gray-200">
              {location.universities}
            </td>
            <td className="relative px-3  py-1 sm:w-12  text-gray-900 sm:px-3 cursor-default dark:text-gray-200">
              {location.companies}
            </td>
            <td className="relative px-3  py-1 sm:w-12 text-gray-900  sm:px-3 cursor-default dark:text-gray-200">
              {location.averageScore}
            </td>
            <td className="relative px-3 py-1 sm:w-12 text-gray-900  sm:px-3 cursor-default dark:text-gray-200">
              {location.cvGrade}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
}
