import React, { useEffect, useRef, useState } from "react";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import CustomCheckIcon from "./CustomCheckIcon";
import { editorToolbarOptions, placeHolder } from "./textEditorConfig";
import ConfirmModal from "../Shared/ConfirmModal";
import { recruitmentProcess } from "../Roles/CreateRole/CreateRoleForm";

type TextEditorProps = {
  textEditorType: string;
  setShowTextEditor: React.Dispatch<React.SetStateAction<boolean>>;
  setJobDescription?: React.Dispatch<React.SetStateAction<string>>;
  setOfferEmail?: React.Dispatch<React.SetStateAction<string>>;
  setRejectionEmail?: React.Dispatch<React.SetStateAction<string>>;
  setProcesses?: React.Dispatch<React.SetStateAction<recruitmentProcess[]>>;
  recruitmentProcessId?: string;
  defaultStateValue?: string;
};

interface ActionHandlerMap {
  [key: string]: (content: string) => void;
}

const defaultTextEditorProps = {
  textEditorType: "",
  setShowTextEditor: () => {
    ("");
  },
  setJobDescription: () => {
    ("");
  },
  setEmailBody: () => {
    ("");
  },
  setOfferEmail: () => {
    ("");
  },
  setRejectionEmail: () => {
    ("");
  },
  setProcesses: () => {
    ("");
  },
  recruitmentProcessId: "",
  defaultStateValue: "",
};

export default function TextEditor({
  textEditorType = defaultTextEditorProps.textEditorType,
  setShowTextEditor = defaultTextEditorProps.setShowTextEditor,
  setJobDescription = defaultTextEditorProps.setJobDescription,
  setOfferEmail = defaultTextEditorProps.setOfferEmail,
  setRejectionEmail = defaultTextEditorProps.setRejectionEmail,
  setProcesses = defaultTextEditorProps.setProcesses,
  recruitmentProcessId = defaultTextEditorProps.recruitmentProcessId,
  defaultStateValue = defaultTextEditorProps.defaultStateValue,
}: TextEditorProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const actionType: string = textEditorType;
  const LOCAL_STORAGE_KEY = `${actionType}`;
  const editorRef = useRef<Editor>(null);

  const [editorContent, setEditorContent] = useState<EditorState>(() => {
    const savedContent = localStorage.getItem(LOCAL_STORAGE_KEY);
    let initialEditorState;

    if (defaultStateValue && typeof defaultStateValue === "string") {
      const replaceCheckMark = () => {
        const defaultHtml = defaultStateValue.replace(
          /<div[^>]+>.*?<svg[^>]+>.*?<\/svg>(.*?)<\/div>/gs,
          (_, innerContent) => {
            const contentWithoutPTags = innerContent.replace(/<\/?p>/g, "");
            return `✔ ${contentWithoutPTags}</p>`;
          }
        );

        return defaultHtml;
      };
      const { contentBlocks, entityMap } = htmlToDraft(replaceCheckMark());
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      initialEditorState = EditorState.createWithContent(contentState);
    } else {
      initialEditorState = savedContent
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(savedContent))
          )
        : EditorState.createEmpty();
    }

    return initialEditorState;
  });

  const handleEditorChange = (newContent: EditorState) => {
    setEditorContent(newContent);
  };

  const textToHtml = () => {
    const currentContent = editorContent.getCurrentContent();
    const contentAsText = convertToRaw(currentContent);
    const contentAsHtml = draftToHtml(contentAsText);

    const updatedHtml = contentAsHtml.replace(
      /✔ ([^\n]+)/g,
      (_, capturedText) =>
        `<div className="flex gap-1"><p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0D42EC" aria-hidden="true" height="20" width="20" className="h-5 w-5 inline-block mx-4 mr-1"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"></path></svg></p><p>${capturedText}</p></div>`
    );

    return updatedHtml;
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, []);

  const actionHandlerMap: ActionHandlerMap = {
    "Job description": setJobDescription,
    "Offer email": setOfferEmail,
    "Rejection email": setRejectionEmail,
    [recruitmentProcessId]: (content) => {
      setProcesses((prevProcesses) =>
        prevProcesses.map((process) =>
          process.id === recruitmentProcessId
            ? { ...process, email: content }
            : process
        )
      );
    },
  };

  const handleClick = (editorAction: string) => {
    const actionHandler = actionHandlerMap[actionType];
    if (editorAction === "save" && actionHandler) {
      actionHandler(textToHtml());
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(convertToRaw(editorContent.getCurrentContent()))
      );
      setShowTextEditor(false);
    }
    if (editorAction === "cancel") {
      setIsModalOpen(true);
    }
    if (editorAction === "delete") {
      setIsModalOpen(false);
      setShowTextEditor(false);
    }
  };

  return (
    <div className="h-screen max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8">
      <div className="h-5/6">
        <Editor
          ref={editorRef}
          editorState={editorContent}
          onEditorStateChange={handleEditorChange}
          stripPastedStyles
          wrapperClassName="h-full"
          toolbarClassName="text-entntblue !rounded-md font-medium flex items-center gap-2 lg:gap-4 ring-1 ring-inset ring-gray-300 shadow-sm dark:!bg-black dark:!border-gray-500 !py-2"
          editorClassName="!h-[calc(100%-1rem)] bg-transparent ring-1 ring-inset ring-gray-300 rounded-md mt-4 px-4 py-2 shadow-sm dark:bg-gray-900 dark:caret-white dark:border dark:border-gray-500 dark:text-white"
          placeholder={placeHolder(actionType)}
          toolbarCustomButtons={[
            actionType === "Job description" ? (
              <CustomCheckIcon
                onChange={handleEditorChange}
                editorContent={editorContent}
              />
            ) : (
              <></>
            ),
            <button
              className="rounded-md bg-entntblue px-5 py-1 text-sm font-semibold text-white shadow-sm"
              onClick={() => handleClick("save")}
            >
              Save
            </button>,
            <button
              className="rounded-md bg-red-500 px-5 py-1 text-sm font-semibold text-white shadow-sm"
              onClick={() => handleClick("cancel")}
            >
              Cancel
            </button>,
          ]}
          toolbar={editorToolbarOptions}
        />
      </div>
      <ConfirmModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={() => handleClick("delete")}
        type="danger"
        title="Close Text Editor"
        description="
        Are you sure you want to discard unsaved changes?
        This action cannot be undone."
      />
    </div>
  );
}
