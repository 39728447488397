import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCandidateApplies } from "../../../WebCalls";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

type props = {
  reApply: string;
  email: string;
};
export default function ReAppliedModal({ reApply, email }: props) {
  const [candidateApplies, setCandidateApplies] = useState<
    { name: string; email: string }[]
  >([]);
  const { roleId } = useParams();

  useEffect(() => {
    if (!roleId) return;
    GetCandidateApplies(roleId, reApply).then((res) => {
      let index = res.findIndex((c) => c.email === email);
      if (index > -1) {
        let [candidate] = res.splice(index, 1);
        res.unshift(candidate);
      }
      setCandidateApplies(res);
    });
  }, [roleId, reApply]);

  return (
    <div className="z-20 absolute top-4 left-4 p-2 border border-red-300 rounded-md bg-gray-50 flex flex-col space-y-2 shadow-md">
      {candidateApplies.map((c, i) => (
        <span
          key={i}
          className={`${c.email !== email ? "text-gray-500" : ""} text-xs flex items-center space-x-2`}
        >
          {c.email !== email && <span>{c.name} - </span>}
          <span>{c.email}</span>
          {c.email === reApply && (
            <CheckBadgeIcon className="h-4 text-entntblue" />
          )}
        </span>
      ))}
    </div>
  );
}
