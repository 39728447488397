import React from "react";

interface MapSettingsProps {
  maxWeight: number;
  setWeightFromPercentage: (percentage: number) => void;
  layerType: "heatmap" | "cluster";
  setLayerType: (type: "heatmap" | "cluster") => void;
  intensity: number;
  setIntensity: (intensity: number) => void;
  radius: number;
  setRadius: (radius: number) => void;
  totalCounts:number;
}

const MapSettings: React.FC<MapSettingsProps> = ({
  maxWeight,
  setWeightFromPercentage,
  layerType,
  setLayerType,
  intensity,
  setIntensity,
  radius,
  setRadius,
  totalCounts,
}) => {
  const percentage = Math.floor((maxWeight / totalCounts) * 100);
  return (
    <div className="p-4">
      <div className="mb-4 flex items-center space-x-4">
        <label className="text-blue-500 mr-4">Layer</label>
        <select
          value={layerType}
          onChange={(e) =>
            setLayerType(e.target.value as "heatmap" | "cluster")
          }
          className="p-2 mr-4 w-full bg-gray-200 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="heatmap" className="bg-white hover:bg-gray-100 mt-4">
            Heat Map
          </option>
          <option value="cluster" className="bg-white hover:bg-gray-100 mt-4">
            Cluster Map
          </option>
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <label className="block text-blue-500 mr-6">Weight:</label>
        <input
          type="range"
          value={percentage}
          onChange={(e) =>setWeightFromPercentage(Number(e.target.value))}
          min="0"
          max="100"
          step="1"
          className="flex-grow h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
        />
        <span className="text-blue-500 ml-4">
          <div className="flex flex-col">
            <div>{maxWeight}</div>
            <div>{percentage}%</div>
          </div>
        </span>
      </div>

      {layerType === "heatmap" && (
        <>
          <div className="mb-4 flex items-center">
            <label className="block text-blue-500 mr-4">Intensity:</label>
            <input
              type="range"
              value={intensity}
              onChange={(e) => setIntensity(Number(e.target.value))}
              min="0"
              max="1"
              step="0.1"
              className="flex-grow h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-blue-500 ml-4">{intensity}</span>
          </div>

          <div className="mb-4 flex items-center">
            <label className="block text-blue-500 mr-6">Radius:</label>
            <input
              type="range"
              value={radius}
              onChange={(e) => setRadius(Number(e.target.value))}
              min="1"
              max="100"
              step="1"
              className="flex-grow h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-blue-500 ml-4">{radius}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default MapSettings;
