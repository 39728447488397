import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "135032ea-8672-46e0-9819-a1bdd23c59b3",
    authority:
      "https://login.microsoftonline.com/246d1166-294d-4f52-83df-388a351a6359/",
    redirectUri: "https://test.recruitment.entnt.in",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (
  //       level: LogLevel,
  //       message: string,
  //       containsPii: boolean
  //     ) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     },
  //   },
  // },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphGetPhotoSmall: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
  graphGetPhotoFull: "https://graph.microsoft.com/v1.0/me/photo/$value",
};
