import React, { useRef } from "react";
import { LocationStats } from "../../../Models";
import { XMarkIcon } from "@heroicons/react/20/solid";
import useClickOutside from "../../../hooks/useClickOutside";
type props = {
  locations: LocationStats[];
  handlePopVisible: () => void;
};
const LocationSubCityPopUp = ({ locations, handlePopVisible }: props) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, () => {
    handlePopVisible();
  });
  
  return (
    <>
      <div
        className="absolute z-30 top-2 left-40 border-gray-200 border-2 rounded shadow-lg  "
        ref={popupRef}
      >
        <div className="bg-white dark:bg-darkbg p-4 rounded cursor-default overflow-y-auto max-h-96 relative">
          <button
            onClick={() => handlePopVisible()}
            className="absolute top-1 left-2 bg-red-600 text-white transition duration-200 ease-in-out transform hover:scale-125 rounded-full "
          >
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          {locations.length > 0 ? (
            <table className="min-w-full p-2">
              <thead>
                <tr>
                  <th className="px-7 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    City
                  </th>

                  <th className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    State
                  </th>

                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    Candidates
                  </th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    Universities
                  </th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    Companies
                  </th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    Avg. Score
                  </th>
                  <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-default">
                    Avg. CvGrade
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:bg-darkbg dark:divide-gray-600">
                {locations.map((location) => (
                  <tr
                    key={location.locationId}
                    className="hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200"
                  >
                    <td className="relative px-7 py-1 text-gray-900 font-normal  sm:w-12 sm:px-6 cursor-default dark:text-gray-200 ">
                      {location.locationName}
                    </td>

                    <td className="relative px-7   py-1 text-gray-900 sm:w-12 sm:px-6 cursor-default dark:text-gray-200 ">
                      {location.locationState}
                    </td>

                    <td className="relative px-7  py-1 text-gray-900  sm:w-12 sm:px-6 cursor-default dark:text-gray-200 ">
                      {location.candidates}
                    </td>
                    <td className="relative px-7   py-1 text-gray-900 sm:w-12 sm:px-6 cursor-default dark:text-gray-200 ">
                      {location.universities}
                    </td>
                    <td className="relative px-7  py-1 text-gray-900  sm:w-12 sm:px-6 cursor-default dark:text-gray-200 ">
                      {location.companies}
                    </td>
                    <td className="relative px-7  py-1 sm:w-12 text-gray-900  sm:px-6 cursor-default dark:text-gray-200">
                      {location.averageScore}
                    </td>
                    <td className="relative px-7 py-1 sm:w-12 text-gray-900  sm:px-6 cursor-default dark:text-gray-200">
                      {location.cvGrade}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className=" min-w-full whitespace-nowrap p-2 font-bold tracking-tight text-entntorange w-full  dark:bg-darkbg text-center   ">
              Sub Cities Not Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LocationSubCityPopUp;
