import React, { useEffect, useRef, useState } from "react";
import * as atlas from "azure-maps-control";
import "azure-maps-control/dist/atlas.min.css";
import Popup from "./Popup";
import { renderToStaticMarkup } from "react-dom/server";
import Loading from "../Shared/Loading";

interface MapProps {
  geoJsonData: atlas.data.FeatureCollection;
  maxWeight: number;
  layerType: "heatmap" | "cluster";
  intensity: number;
  radius: number;
  popupProperties: string[];
}

const Map: React.FC<MapProps> = ({
  geoJsonData,
  maxWeight,
  layerType,
  intensity,
  radius,
  popupProperties,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<atlas.Popup | null>(null);
  const [isLoading, serIsLoading] = useState(true);
  const apiKey = process.env.REACT_APP_AZURE_MAP_KEY;
  useEffect(() => {
    serIsLoading(true);
    if (!mapRef.current) return;

    const map = new atlas.Map(mapRef.current, {
      center: [79.9629, 21.5937],
      zoom: 4,
      style: "grayscale_light",
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: apiKey,
      },
      showFeedbackLink: false,
      showLogo: false,
    });

    map.controls.add(
      [
        new atlas.control.StyleControl(),
        new atlas.control.ZoomControl(),
        new atlas.control.CompassControl(),
        new atlas.control.PitchControl(),
      ],
      {
        position: atlas.ControlPosition.TopRight,
      }
    );

    map.events.add("ready", () => {
      const heatMapSource = new atlas.source.DataSource();
      map.sources.add(heatMapSource);
      const dataSource = new atlas.source.DataSource(undefined, {
        cluster: true,
        clusterRadius: 60,
        clusterMaxZoom: 15,
      });

      const polygonDatasource = new atlas.source.DataSource();
      map.sources.add([polygonDatasource, dataSource]);

      if (geoJsonData) {
        dataSource.add(geoJsonData);
        heatMapSource.add(geoJsonData);

        if (layerType === "heatmap") {
          const heatmapLayer = new atlas.layer.HeatMapLayer(
            heatMapSource,
            undefined,
            {
              radius: radius,
              weight: [
                "interpolate",
                ["linear"],
                ["get", "count"],
                0,
                0,
                maxWeight,
                1,
              ],
              color: [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(33,102,172,0)", // Transparent blue
                0.2,
                "rgb(103,169,207)", // Light blue
                0.4,
                "rgb(209,229,240)", // Lightest blue
                0.6,
                "rgb(253,219,199)", // Light orange
                0.8,
                "rgb(239,138,98)", // Orange
                1,
                "rgb(178,24,43)", // Red
              ],
              intensity: intensity,
              opacity: 0.7,
              blur: 15,
            }
          );

          map.layers.add(heatmapLayer, "labels");
          const symbolLayer = new atlas.layer.SymbolLayer(
            heatMapSource,
            undefined,
            {
              iconOptions: {
                image: "none",
              },
              textOptions: {
                textField: "",
              },
            }
          );

          map.layers.add(symbolLayer);
        } else if (layerType === "cluster") {
          const clusterBubbleLayer = new atlas.layer.BubbleLayer(
            dataSource,
            undefined,
            {
              radius: [
                "step",
                ["get", "point_count"],
                20, // If 'point_count' is less than 400, the radius is 20 pixels.
                400,
                30, // If 'point_count' is between 400 and 800, the radius is 30 pixels.
                800,
                40, // If 'point_count' is greater than 800, the radius is 40 pixels.
              ],
              color: [
                "step",
                ["get", "point_count"],
                "rgba(0,255,0,0.8)",
                (maxWeight * 2) / 3,
                "rgba(255,255,0,0.8)",
                maxWeight,
                "rgba(255,0,0,0.8)",
              ],
              intensity: intensity,
              strokeWidth: 0,
              filter: ["has", "point_count"],
            }
          );

          const symbolLayer = new atlas.layer.SymbolLayer(
            dataSource,
            undefined,
            {
              filter: ["!", ["has", "point_count"]],
            }
          );

          const clusterSymbolLayer = new atlas.layer.SymbolLayer(
            dataSource,
            undefined,
            {
              iconOptions: {
                image: "none",
              },
              textOptions: {
                textField: ["get", "point_count_abbreviated"],
                offset: [0, 0.4],
              },
            }
          );

          map.layers.add([clusterBubbleLayer, clusterSymbolLayer, symbolLayer]);
        }
      }

      const popup = new atlas.Popup({
        closeButton: false,
        offset: [0, -18],
      });
      popupRef.current = popup;

      let popupTimeout: NodeJS.Timeout;
      map.events.add(
        "mouseover",
        map.layers.getLayers(),
        (e: atlas.MapMouseEvent) => {
          if (e.shapes && e.shapes.length > 0) {
            const shape = e.shapes[0] as any;
            const properties = shape.getProperties
              ? shape.getProperties()
              : shape.properties;
            const coordinates = e.position;
            const hasAllPopupProperties = popupProperties.every((prop) =>
              properties.hasOwnProperty(prop)
            );

            if (coordinates && properties && hasAllPopupProperties) {
              const content = renderToStaticMarkup(
                <Popup
                  properties={properties}
                  popupProperties={popupProperties}
                />
              );

              popup.setOptions({
                content: content,
                position: coordinates,
              });

              popup.open(map);
              if (popupTimeout) {
                clearTimeout(popupTimeout);
              }

              popupTimeout = setTimeout(() => {
                popup.close();
              }, 1000);
            }
          } else {
            popup.close();
          }
        }
      );

      // const stateboundarySource = new atlas.source.DataSource();
      // map.sources.add(stateboundarySource);
      // fetch("./states_india.geojson")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     stateboundarySource.add(data);

      //     map.layers.add(
      //       new atlas.layer.LineLayer(stateboundarySource, undefined, {
      //         strokeColor: "black",
      //         strokeWidth: 1,
      //         strokeOpacity: 0.4,
      //       })
      //     );
      //   });

      const boundarySource = new atlas.source.DataSource();
      map.sources.add(boundarySource);
      fetch("./india_boundary.geojson")
        .then((response) => response.json())
        .then((data) => {
          boundarySource.add(data);

          map.layers.add(
            new atlas.layer.LineLayer(boundarySource, undefined, {
              strokeColor: "red",
              strokeWidth: 2,
            })
          );
        });
      serIsLoading(false);
    });

    return () => map.dispose();
  }, [geoJsonData, maxWeight, layerType, intensity, radius, popupProperties]);

  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <Loading />
        </div>
      )}
      <div
        className="rounded"
        ref={mapRef}
        style={{ height: "100%", width: "100%", position: "relative" }}
      />
    </div>
  );
};

export default Map;
