import React, {  useEffect, useRef, useState } from "react";
import { History } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
const tabs = ["SubCity", "City", "State"];


type locationSidebarProps = {
  currentTab:string,
  handleCurrentTab: (query: string) => void; 
  handleMajorCityClick: () => void;
  handleSubCityClick: () => void;
};
export default function LocationSidebar({
  currentTab,
  handleCurrentTab,
  handleMajorCityClick,
  handleSubCityClick,
}: locationSidebarProps) {
  const navigate = useNavigate(); 

  const handleActionsLogClick = () => {
    navigate('/location/actionLogs');  // navigate to the action log page
  };
  return (
    <div className="px-4">
      {/* <Disclosure.Panel className="pt-4 relative"> */}
      {/* <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 dark:border-gray-500"
      >
        {({ open }) => (
          <div className="relative">
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                <span className="font-medium text-black dark:text-gray-300">
                  Role
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>

            <Disclosure.Panel className="pt-4 relative">
              {rolesFilter.options.length === 0 ? (
                <div className="text-xs">No roles available</div>
              ) : (
                <div className="space-y-2">
                  {rolesFilter.options.map((option, optionIdx) => (
                    <div key={optionIdx} className="flex items-center">
                      <input
                        id={`role-${optionIdx}`}
                        name={`role-${optionIdx}`}
                        type="checkbox"
                        onChange={() => handleRoleFilterChange(option)}
                        checked={option.checked}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={`role-${optionIdx}`}
                        className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                      >
                        <span>{option.label}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure> */}
      
      <div
          className="flex w-full mt-8 items-center justify-between py-2 px-3 text-gray-400 cursor-pointer hover:text-gray-900"
          onClick={handleActionsLogClick}
        >
          <span className="font-medium text-sm text-gray-900 dark:text-gray-300">
            Actions Log
          </span>
            <History
              className={"w-4"}
              aria-hidden="true"
            />
          
        </div>
      {currentTab === tabs[0] && (
        <div
          className="inline-flex items-center  justify-center ml-3 mt-8 mb-1 rounded min-w-[13rem] bg-blue-600 hover:bg-entntorange px-3 py-2 text-white shadow-sm cursor-pointer transition duration-200 ease-in-out transform hover:scale-105"
          onClick={handleMajorCityClick}
        >
          <button className="text-sm font-semibold">Make Major City</button>
        </div>
      )}

      {currentTab === tabs[1] && (
        <div
          className="inline-flex items-center justify-center ml-3 mt-8 mb-1 rounded min-w-[13rem] bg-blue-600 hover:bg-entntorange px-3 py-2 text-white shadow-sm cursor-pointer transition duration-200 ease-in-out transform hover:scale-105"
          onClick={handleSubCityClick}
        >
          <button className="text-sm font-semibold">Make Sub City</button>
        </div>
      )}
    </div>
  );
}
