import React from "react";

interface PopupProps {
  properties: { [key: string]: any };
  popupProperties: string[];
}

const Popup: React.FC<PopupProps> = ({ properties, popupProperties }) => {
  return (
    <div className="inline-block px-3 py-2 text-xs font-medium text-black bg-gray-100 rounded-lg shadow-sm tooltip dark:bg-gray-700 dark:text-white">
      {popupProperties.map((prop) => (
        <div key={prop}>
          <b>{prop}:</b> {properties[prop]?.toString() ?? "N/A"}
        </div>
      ))}
    </div>
  );
};

export default Popup;
