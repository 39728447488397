import {
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronRightIcon,
  MapPinIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchLocationActionsLog } from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";
import {
  LocationActionsLog,
  NotificationModel,
} from "../../../Models";
import { Spinner } from "../../Shared/Spinner";
import Notification from "../../Shared/Notification";

export default function ActionsLog() {
  const [actionLogs, setActionLogs] = useState<LocationActionsLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifcationInfo, setNotifcationInfo] = useState<NotificationModel>({
    title: "",
    description: "",
    type: "success",
  });
  const token = useApiToken();
  useEffect(() => {
    if(token=== undefined){
      return;
    }
    fetchActions();
    
  }, [token]);
  const fetchActions = async () => {
    setLoading(true);
    try {
      const data = await fetchLocationActionsLog(token); 
      setActionLogs(data);
      setLoading(false);
    } catch (e) {
      setActionLogs([]);
      setLoading(false);
    }
  };


  const getActionIcon = (actionType: string) => {
    const actionTypeLower = actionType.toLowerCase();

    switch (actionTypeLower) {
      case "made major city":
        return <ChevronDoubleUpIcon className="text-entntblue text-4xl" />;
      case "made sub city":
        return <ChevronDoubleDownIcon className="text-entntblue text-4xl" />; 
      case "major city changed":
        return <ArrowRightIcon className="text-entntblue text-4xl" />;
      default:
        return (
          <XMarkIcon className="w-[1.9rem] bg-red-600 text-white rounded-full" />
        );
    }
  };

  return (
    <>
      

      <header className="bg-gray-50 py-8 dark:bg-darkbglight">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link
                      to="/location"
                      className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      Locations
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                      Actions Log
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight dark:text-gray-300">
              Actions Log
            </h1>
          </div>
        </div>
      </header>
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <Spinner height="h-10" width="w-10" />
        </div>
      ) : actionLogs.length === 0 ? (
        <div className="bg-blue-100 rounded-lg py-8 mt-8 dark:bg-darkbglight font-bold items-center text-center dark:text-gray-400">
          <h1>Actions Log is Empty</h1>
        </div>
      ) : (
        <ul className="relative before:content-[''] before:absolute before:top-0 before:left-[6rem] md:before:left-[8rem] before:z-[-10] before:w-[2px] before:h-full before:bg-gray-300 before:-translate-x-1/2">
          {actionLogs.map((transaction) => (
            <li key={transaction.transactionId} className="my-4">
              {/* Actions within each transaction */}
              <div className="px-4 py-2 border-b border-gray-300">

                {transaction.actions.map((action: any) => (
                  <div
                    key={action.id}
                    className="flex flex-row items-center justify-around px-4 py-2 my-2"
                  >
                    <div className="flex flex-col items-center bg-white">
                      {getActionIcon(action.actionType)}

                      <div className="flex items-center text-[0.85rem] text-gray-500">
                        {new Date(action.actionDate).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}
                      </div>
                    </div>

                    <div className="px-4 py-2 grid grid-cols-6 sm:flex-1 max-w-[80%] border-b border-gray-300">
                      <div className="col-span-2">
                        <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
                          {action.city.name}
                        </h1>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                          <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue" />
                          {action.newParent?.name
                            ? action.newParent.name
                            : "New Major City"}
                        </div>
                      </div>
                      <div className="col-span-3 text-sm">
                        <span>
                          {(() => {
                            switch (action.actionType.toLowerCase()) {
                              case "made major city":
                                return (
                                  <>
                                    Made a major city from{" "}
                                    <span className="text-entntblue">
                                      {action.oldParent?.name}
                                    </span>
                                  </>
                                );
                              case "made sub city":
                                return (
                                  <>
                                    Made a sub city of{" "}
                                    <span className="text-entntblue">
                                      {action.newParent?.name}
                                    </span>
                                  </>
                                );
                              case "major city changed":
                                return (
                                  <>
                                    Major city changed from{" "}
                                    <span className="text-entntblue">
                                      {action.oldParent?.name}
                                    </span>{" "}
                                    to{" "}
                                    <span className="text-entntblue">
                                      {action.newParent?.name}
                                    </span>
                                  </>
                                );
                              default:
                                return (
                                  <span>
                                    {action.actionType} for{" "}
                                    <span className="text-entntblue">
                                      {action.oldParent?.name ||
                                        action.newParent?.name}
                                    </span>
                                    .
                                  </span>
                                );
                            }
                          })()}
                        </span>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                          <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue" />
                          {new Date(action.actionDate).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ul>
      )}

      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title={notifcationInfo.title}
        description={notifcationInfo.description}
        type={notifcationInfo.type}
      />
    </>
  );
}
