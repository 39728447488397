import LocationGridHeader from "./LocationGridHeader";
import { LocationStats } from "../../../Models";
import { useEffect, useMemo, useRef, useState } from "react";
import useApiToken from "../../../hooks/useApiToken";
import {
  GetLocationStats,
  GetSubCities,
  paginationData,
} from "../../../WebCalls";
import { Spinner } from "../../Shared/Spinner";
import LocationGridItemList from "./LocationGridItemList";
import PaginationComponent from "../../Shared/PaginationComponent";
type LocationData = {
  locations: LocationStats[];
  paginationMetadata: paginationData;
};
type CacheKey = string;
type CacheValue = {
  data: LocationData;
  timestamp: number;
};

const cache = new Map<CacheKey, CacheValue>();

const createCacheKey = (
  locationFilterQuery: string,
  candidateFilterQuery: string,
  universityFilterQuery: string,
  companyFilterQuery: string,
  sortQuery: string,
  currentPage: number,
  searchQuery: string,
  currrentTab: string,
  atTheTime: number
): CacheKey => {
  return `${locationFilterQuery}_${candidateFilterQuery}_${universityFilterQuery}_${companyFilterQuery}_${sortQuery}_${currentPage}_${searchQuery}_${currrentTab}_${atTheTime}`;
};

const CACHE_EXPIRATION_TIME = 1 * 60 * 1000;
type locationGridProps = {
  locationFilterQuery: string;
  candidateFilterQuery: string;
  universityFilterQuery: string;
  companyFilterQuery: string;
  searchQuery: string;
  currrentTab: string;
  univRankTypeId: string;
  compRankTypeId: string;
  selectedColumns: { id: string; name: string }[];
  setSelectedColumns: React.Dispatch<
    React.SetStateAction<{ id: string; name: string }[]>
  >;
  atTheTime: number;

  refetchTrigger: boolean;
};
export default function LocationGrid({
  locationFilterQuery,
  candidateFilterQuery,
  universityFilterQuery,
  companyFilterQuery,
  searchQuery,
  currrentTab,
  univRankTypeId,
  compRankTypeId,
  selectedColumns,
  setSelectedColumns,
  atTheTime,
  refetchTrigger,
}: locationGridProps) {
  const [locations, setLocations] = useState<LocationStats[]>([]);
  const [paginationData, setPaginationData] = useState<paginationData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortQuery, setSortQuery] = useState<string>("");
  const token = useApiToken();
  const currentRequestId = useRef<number | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [clickedCity, setClickedCity] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState<LocationStats[]>([]);
  const [isPopupLoading, setIsPopupLoading] = useState(false);

  useEffect(() => {
    setSortQuery("");
    setLocations([]);
    setCurrentPage(1);
    setTotalPages(1);
  }, [currrentTab]);

  useEffect(() => {
    setLocations([]);
    setCurrentPage(1);
    setTotalPages(1);
  }, [
    locationFilterQuery,
    candidateFilterQuery,
    universityFilterQuery,
    companyFilterQuery,
    sortQuery,
    searchQuery,
    currrentTab,
    atTheTime,
  ]);
  useEffect(() => {
    setLocations([]);
  }, [
    locationFilterQuery,
    candidateFilterQuery,
    universityFilterQuery,
    companyFilterQuery,
  ]);
  const cacheKey = useMemo(
    () =>
      createCacheKey(
        locationFilterQuery,
        candidateFilterQuery,
        universityFilterQuery,
        companyFilterQuery,
        sortQuery,
        currentPage,
        searchQuery,
        currrentTab,
        atTheTime
      ),
    [
      locationFilterQuery,
      candidateFilterQuery,
      universityFilterQuery,
      companyFilterQuery,
      sortQuery,
      currentPage,
      searchQuery,
      currrentTab,
      atTheTime,
    ]
  );
  useEffect(() => {
    setLoading(true);
    if (token === undefined) {
      setLoading(false);
      return;
    }

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      const cachedData = cache.get(cacheKey);

      if (
        cachedData &&
        Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME
      ) {
        // Use cached data if it's still valid
        setLocations(cachedData.data.locations);
        setPaginationData(cachedData.data.paginationMetadata);
        setTotalPages(cachedData.data.paginationMetadata.totalPages);
        setLoading(false);
      } else {
        if (cachedData) {
          cache.delete(cacheKey);
        }

        const requestId = Math.random();
        currentRequestId.current = requestId;
        getLocationsData(requestId);
      }
    }, 500);
  }, [token, cacheKey]);
  useEffect(() => {
    if (clickedCity != null && clickedCity.length > 0 && token != undefined) {
      setIsPopupLoading(true);
      GetSubCities(
        locationFilterQuery,
        candidateFilterQuery,
        universityFilterQuery,
        companyFilterQuery,
        sortQuery,
        searchQuery,
        token,
        univRankTypeId,
        compRankTypeId,
        atTheTime,
        clickedCity
      )
        .then((ls) => {
          setPopupData(ls);
          setPopupVisible(true);
        })
        .finally(() => {
          setIsPopupLoading(false);
        });
    }
  }, [clickedCity]);
  useEffect(() => {
    if (token === undefined) {
      return;
    }
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    setLoading(true);

    const requestId = Date.now();
    currentRequestId.current = requestId;

    getLocationsData(requestId);
  }, [refetchTrigger]);

  const handleSelectColumnData = (id: string, name: string) => {
    setSelectedColumns((prevSelectedColumns) =>
      prevSelectedColumns.some((selectedCity) => selectedCity.id === id)
        ? prevSelectedColumns.filter((selectedCity) => selectedCity.id !== id)
        : [...prevSelectedColumns, { id, name }]
    );
  };
  const handleSelectedAll = (flag: boolean) => {
    if (flag) {
      const allSelectedCities = locations.map((location) => ({
        id: location.locationId,
        name: location.locationName,
      }));
      setSelectedColumns(allSelectedCities);
    } else {
      setSelectedColumns([]);
    }
  };

  const getLocationsData = async (requestId: number) => {
    GetLocationStats(
      locationFilterQuery,
      candidateFilterQuery,
      universityFilterQuery,
      companyFilterQuery,
      sortQuery,
      searchQuery,
      currentPage,
      currrentTab,
      token,
      univRankTypeId,
      compRankTypeId,
      atTheTime
    )
      .then((ls) => {
        if (currentRequestId.current === requestId) {
          cache.set(cacheKey, { data: ls, timestamp: Date.now() });
          setLocations(ls.locations);
          setPaginationData(ls.paginationMetadata);
          setTotalPages(ls.paginationMetadata.totalPages);
        }
      })
      .finally(() => {
        if (currentRequestId.current === requestId) {
          setLoading(false);
        }
      });

    return () => {
      currentRequestId.current = null;
    };
  };

  return (
    <div className=" bg-white dark:bg-darkbg">
      <div className="relative">
        <table className="min-w-full table-fixed divide-y  ">
          <LocationGridHeader
            currentTab={currrentTab}
            handleSort={(query: string) => {
              setSortQuery(query);
            }}
            handleSelectedAll={handleSelectedAll}
          />
          {loading ? (
            <tbody>
              <tr className="h-80  dark:bg-darkbglight ">
                <td colSpan={9}>
                  <Spinner height="h-10" width="w-10" />
                </td>
              </tr>
            </tbody>
          ) : !locations ? (
            <tbody>
              <tr className="h-100">
                <td
                  colSpan={9}
                  className="text-center mt-20 text-base leading-7 text-gray-600 dark:text-gray-400  dark:bg-darkbglight"
                >
                  Loading locations...
                </td>
              </tr>
            </tbody>
          ) : locations.length === 0 ? (
            <tbody>
              <tr className="h-80">
                <td
                  colSpan={9}
                  className="text-center mt-20   dark:bg-darkbglight text-base leading-7 text-gray-600 dark:text-gray-400  "
                >
                  No Location Found
                </td>
              </tr>
            </tbody>
          ) : (
            <LocationGridItemList
              stateColumn={
                currrentTab === "City" || currrentTab === "SubCity"
                  ? true
                  : false
              }
              popupVisible={popupVisible}
              popupData={popupData}
              isPopupLoading={isPopupLoading}
              nearestCityColumn={currrentTab === "SubCity" ? true : false}
              locations={locations}
              selectedColumn={selectedColumns}
              handleSelectColumn={handleSelectColumnData}
              handleCityClicked={(id) => setClickedCity(id)}
              handlePopUpVisible={(flag) => {
                setPopupVisible(flag);
                setClickedCity("");
              }}
            />
          )}
        </table>
      </div>
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages ? totalPages : paginationData?.totalPages || 1}
        handlePageChange={(page: number) => setCurrentPage(page)}
      />
    </div>
  );
}
